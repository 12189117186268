import { FormState } from '../../../utils/state/initialStateFactory';
import { FormContext } from '../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import { createOnSubmitAction, OnSubmit } from './onSubmit/onSubmit';
import {
  createSetPaymentOptionAction,
  SetPaymentOption,
} from './setPaymentOption/setPaymentOption';
import { createOnLoginAction, OnLogin } from './onLogin/onLogin';
import { createSetCouponAction, SetCoupon } from './setCoupon/setCoupon';

export type FormControllerActions = {
  onSubmit: OnSubmit;
  setCoupon: SetCoupon;
  setPaymentOptionAction: SetPaymentOption;
  onLogin: OnLogin;
};

export const createFormActions = (
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): FormControllerActions => ({
  onSubmit: createOnSubmitAction(actionFactoryParams),
  setPaymentOptionAction: createSetPaymentOptionAction(actionFactoryParams),
  onLogin: createOnLoginAction(actionFactoryParams),
  setCoupon: createSetCouponAction(actionFactoryParams),
});

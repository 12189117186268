import React from 'react'
import classNames from 'classnames'
import {DH} from '../../../constants/data-hooks'
import {ClosedRegistrationButton} from '../../registration-buttons/closed-registration-button'
import {EditRegistrationButton} from '../../registration-buttons/edit-registration-button'
import {RegistrationButtonSkin} from '../../registration-buttons/registration-button-skin'
import {TicketsUnavailableButton} from '../../registration-buttons/tickets-unavailable-button'
import s from './registration-button.scss'
import {RegistrationButtonProps} from './index'

export const RegistrationButton = ({
  ticketedEvent,
  registrationButtonVisible,
  registrationClosed,
  memberRsvpExists,
  rsvpButtonText,
  buttonStyle,
  onClick,
  imageVisible,
  navigateToMainPage,
  secondary,
  isMobile,
  ticketsUnavailable,
  className,
  url,
  isExternalLink,
}: RegistrationButtonProps) => {
  const getButton = () => {
    const button = (
      <RegistrationButtonSkin
        buttonStyle={buttonStyle}
        text={rsvpButtonText}
        onClick={onClick}
        url={url}
        isExternalLink={isExternalLink}
        dataHook={ticketedEvent ? DH.HEADER_GET_TICKETS_BUTTON : DH.RSVP_BUTTON}
        secondary={secondary}
      />
    )

    if (!isMobile && secondary && (ticketedEvent || !registrationButtonVisible)) {
      return null
    }

    if (ticketsUnavailable) {
      return <TicketsUnavailableButton onClick={navigateToMainPage} />
    }

    if (registrationClosed) {
      return <ClosedRegistrationButton onClick={navigateToMainPage} />
    }

    if (memberRsvpExists) {
      return <EditRegistrationButton />
    }

    if (!registrationButtonVisible) {
      return isMobile && ticketedEvent && secondary ? button : null
    }

    return button
  }

  const button = getButton()

  return button ? (
    <ButtonContainer hasImageButtonSpacing={isMobile || imageVisible} secondary={secondary} className={className}>
      {button}
    </ButtonContainer>
  ) : null
}

interface ButtonContainerProps {
  hasImageButtonSpacing: boolean
  secondary: boolean
  className: string
}
const ButtonContainer: React.FC<ButtonContainerProps> = ({hasImageButtonSpacing, secondary, className, children}) => (
  <div
    className={classNames(
      hasImageButtonSpacing ? s.registrationButtonSpacing : s.registrationButtonSpacingNoImage,
      {
        [s.secondaryButtonSpacing]: secondary,
      },
      className,
    )}
  >
    {children}
  </div>
)

import React from 'react';
import { classes, st } from './BookButton.st.css';
import { BookButtonDataHooks } from './dataHooks';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Button, SIZE } from 'wix-ui-tpa/Button';
import { useFormActions } from '../../Hooks/useFormActions';
import { PaymentType, Service } from '../../../../utils/mappers/service.mapper';

export interface BookButtonProps {
  service: Service;
}
const BookButton: React.FC<BookButtonProps> = ({ service }) => {
  const { t } = useTranslation();
  const { onSubmit, submitForm } = useFormActions();
  const { isMobile } = useEnvironment();

  const actionLabel = getActionLabel(service);

  return (
    <div className={st(classes.root, { isMobile })}>
      <Button
        size={SIZE.medium}
        className={classes.bookButton}
        data-hook={BookButtonDataHooks.ACTION_BUTTON}
        upgrade
        onClick={() => {
          const submissionResponse = submitForm?.();
          if (submissionResponse) {
            onSubmit(submissionResponse);
          }
        }}
      >
        {t(actionLabel)}
      </Button>
    </div>
  );
};

const getActionLabel = (service: Service): string => {
  if (service.isPendingApprovalFlow) {
    return service.actionLabels?.bookingRequestApprovalLabel!;
  } else if (canOnlyBePaidOnline(service?.paymentTypes!)) {
    return service.actionLabels?.onlinePaymentLabel!;
  } else {
    return service.actionLabels?.offlinePaymentLabel!;
  }
};

const canOnlyBePaidOnline = (paymentTypes: PaymentType[]) =>
  paymentTypes?.length === 1 && paymentTypes?.includes(PaymentType.ONLINE);

export default BookButton;

import React, { useEffect, useState } from 'react';
import { TimePickerViewModel } from '../../../ViewModel/timePickerViewModel/timePickerViewModel';
import { TimePickerDataHooks } from './TimePickerDataHooks';
import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './TimePicker.st.css';
import { AccessibilityHtmlTags } from '../../../../../utils/AccessibilityHtmlTags.const';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { SPINNER_TYPES } from 'wix-ui-tpa/dist/src/components/Spinner/types';
import NoAvailableSlots from '../../NoAvailableSlots/NoAvailableSlots';
import SlotsSelection from '../../SlotsSelection/SlotsSelection';
import TimezoneSelection from '../../TimezoneSelection/TimezoneSelection';
import SlotsNotification from '../../SlotsNotification/SlotsNotification';
import { FlowElements, useFlow } from '../../../Hooks/useFlow';
import { SlotsStatus } from '../../../../../types/types';

export type TimePickerProps = {
  viewModel: TimePickerViewModel;
};

const TimePicker: React.FC<TimePickerProps> = ({ viewModel }) => {
  const {
    formattedSelectedDate,
    timezoneSelectionViewModel,
    noAvailableSlotsViewModel,
    slotsSelectionViewModel,
    slotsNotificationViewModel,
    accessibility: { onTimePickerLoadedAnnouncement },
  } = viewModel;
  const { isMobile, isRTL } = useEnvironment();
  const timePickerRef = useFlow(FlowElements.TIME_PICKER);
  const [srOnlyAnnouncement, setSROnlyAnnouncement] = useState('');
  const getTimePickerLoader = () => (
    <div
      data-hook={TimePickerDataHooks.LOADER}
      className={st(classes.spinnerWrapper, {
        isMobile,
      })}
    >
      <Spinner
        className={classes.spinner}
        diameter={24}
        isCentered={true}
        type={SPINNER_TYPES.slim}
      />
    </div>
  );

  const getTimePickerContentByStatus = () => {
    switch (viewModel.status) {
      case SlotsStatus.LOADING:
        return getTimePickerLoader();
      case SlotsStatus.NO_AVAILABLE_SLOTS:
        return <NoAvailableSlots viewModel={noAvailableSlotsViewModel} />;
      case SlotsStatus.AVAILABLE_SLOTS:
        return (
          <div>
            <SlotsNotification viewModel={slotsNotificationViewModel} />
            <SlotsSelection viewModel={slotsSelectionViewModel} />
          </div>
        );
      default:
        return getTimePickerLoader();
    }
  };

  useEffect(() => {
    setSROnlyAnnouncement('');
    if (viewModel.status !== SlotsStatus.LOADING) {
      setTimeout(
        () => setSROnlyAnnouncement(onTimePickerLoadedAnnouncement),
        300,
      );
    }
  }, [viewModel.status]);

  return (
    <div className={st(classes.root, { isMobile, isRTL })} ref={timePickerRef}>
      <div
        id="announcement"
        role="alert"
        className={classes.srOnly}
        data-hook={TimePickerDataHooks.SR_ONLY_ANNOUNCEMENT}
      >
        {srOnlyAnnouncement}
      </div>
      <div
        data-hook={TimePickerDataHooks.TIME_PICKER}
        role="region"
        aria-labelledby="selected-date"
      >
        <SelectedDate selectedDate={formattedSelectedDate!} />
        {timezoneSelectionViewModel ? (
          <TimezoneSelection viewModel={timezoneSelectionViewModel} />
        ) : null}
        {getTimePickerContentByStatus()}
      </div>
    </div>
  );
};

export default TimePicker;

type SelectedDateProps = {
  selectedDate: string;
};

const SelectedDate: React.FC<SelectedDateProps> = ({
  selectedDate,
}): JSX.Element => {
  return (
    <Text
      id="selected-date"
      data-hook={TimePickerDataHooks.SELECTED_DATE}
      className={st(classes.formattedSelectedDate)}
      tagName={AccessibilityHtmlTags.Paragraph}
      aria-hidden="true"
    >
      {selectedDate}
    </Text>
  );
};

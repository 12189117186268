import React from 'react';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import { useSettings, useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';

import { classes } from './Ribbon.st.css';
import stylesParams from '../../stylesParams';
import { useSettingsParams } from '../../../../services/settings-params-context';

interface Props {
  highlighted: boolean;
}

export const Ribbon: React.FC<Props> = ({ highlighted }) => {
  const { t } = useTranslation();
  const settingsParams = useSettingsParams();
  const settings = useSettings();
  const styles = useStyles();
  const showRibbon = styles.get(stylesParams.showRibbon);

  if (!highlighted || !showRibbon) {
    return null;
  }

  return (
    <div data-hook="ribbon" className={classes.ribbon}>
      {settings.get(settingsParams.ribbonText) || t('pp.defaults.ribbon')}
    </div>
  );
};

import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';
import { AddError } from '../addError/addError';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import {
  getEndOfMonthAsLocalDateTime,
  isMonthDifferent,
} from '../../../../utils/dateAndTime/dateAndTime';
import { SlotsStatus, TriggeredByOptions } from '../../../../types/types';

export type GoToNextAvailableDate = () => void;

export const NO_NEXT_AVAILABLE_DATE = 'NO_NEXT_AVAILABLE_DATE';

export function createGoToNextAvailableDateAction(
  {
    getControllerState,
    context: { biLogger, calendarApi, settings },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
  addError: AddError,
): GoToNextAvailableDate {
  return async () => {
    const [state, setState] = getControllerState();
    const { selectedDate } = state;

    setState({
      slotsStatus: SlotsStatus.LOADING,
    });

    const threeMonthsFromSelectedDate = getEndOfMonthAsLocalDateTime(
      selectedDate!,
      4,
    );
    const nextAvailableLocalDateTime = await calendarApi.getNextAvailableDate(
      {
        fromAsLocalDateTime: selectedDate!,
        toAsLocalDateTime: threeMonthsFromSelectedDate,
      },
      { state, settings, onError: addError },
    );

    if (nextAvailableLocalDateTime) {
      const shouldFetchDateAvailability = isMonthDifferent(
        selectedDate!,
        nextAvailableLocalDateTime,
      );

      await Promise.all([
        setSelectedDate(
          nextAvailableLocalDateTime,
          TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
        ),
        shouldFetchDateAvailability
          ? setSelectedMonth(
              nextAvailableLocalDateTime,
              TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
            )
          : Promise.resolve(),
      ]);

      void biLogger.bookingsCalendarClick({
        component: WidgetComponents.TIME_PICKER,
        element: WidgetElements.GO_TO_NEXT_AVAILABLE_DATE_LINK,
        properties: JSON.stringify({
          selectedDate,
          nextAvailableDate: nextAvailableLocalDateTime,
        }),
      });
    } else {
      setState({
        slotsStatus: SlotsStatus.NO_AVAILABLE_SLOTS,
      });
      void biLogger.bookingsCalendarErrorMessages({
        errorMessage: NO_NEXT_AVAILABLE_DATE,
      });
    }
  };
}

import React from 'react';
import { classes, st } from './DateAndTime.st.css';
import { Text } from 'wix-ui-tpa/Text';
import {
  TFunction,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { DateAndTimeDataHooks } from './dataHooks';
import { isServiceTypeIsCourse } from '../../../../utils/mappers/service.mapper';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { ServiceType } from '@wix/bookings-uou-types';

export interface DateAndTimeProps {
  dateRegionalSettingsLocale: string;
  serviceType: ServiceType;
  startDate: string;
  totalNumberOfSessions: number;
}
const DateAndTime: React.FC<DateAndTimeProps> = ({
  totalNumberOfSessions,
  startDate,
  dateRegionalSettingsLocale,
  serviceType,
}) => {
  const { t } = useTranslation();
  const { isMobile, isEditor } = useEnvironment();
  const isCourse = isServiceTypeIsCourse(serviceType);
  const dateAndTime = isEditor
    ? t('app.dummy-data.date-and-time')
    : getDateAndTime(
        isCourse,
        startDate,
        dateRegionalSettingsLocale,
        totalNumberOfSessions,
        t,
      );

  return (
    <div className={st(classes.root, { isMobile })}>
      <Text
        data-hook={DateAndTimeDataHooks.SLOT_DATE_AND_TIME}
        className={classes.primaryText}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {dateAndTime}
      </Text>
    </div>
  );
};

const getDateAndTime = (
  isCourse: boolean,
  startDate: string,
  dateRegionalSettingsLocale: string,
  totalNumberOfSessions: number,
  t: TFunction,
) => {
  const locale = dateRegionalSettingsLocale!;
  const dateAndTimeFormatter = new DateTimeFormatter(locale);
  const dateAndTimeFormat = dateAndTimeFormatter.formatDateAndTime(startDate);
  const isServiceHasMultipileSessions = totalNumberOfSessions > 1;

  return isCourse && isServiceHasMultipileSessions
    ? t('app.booking-details.course.date-and-time.starts.text', {
        date: dateAndTimeFormat,
      })
    : dateAndTimeFormat;
};

export default DateAndTime;

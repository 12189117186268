import React from 'react'
import classNames from 'classnames'
import {DH} from '../../../../constants/data-hooks'
import {namesToClasses} from '../../../names-to-classes'
import {AddToCalendar} from './add-to-calendar'
import {DownloadTickets} from './download-tickets'
import {SuccessActionsProps} from './interfaces'
import s from './success-actions.scss'

const getButtonClasses = (formButtonStyle: number, primary: boolean = true) => {
  const round = [2, 4].includes(formButtonStyle)
  const hollow = [1, 2].includes(formButtonStyle)
  const roundClassName = `formButton${round ? 'Rounded' : ''}BorderRadius`
  const hollowButtonClasses = `formHollowButtonFont formHollowButtonColor formButtonBorder`
  const fullButtonClasses = `formFullButtonFont formFullButtonColor formButtonBackgroundColor`
  const primaryClasses = namesToClasses(`${hollow ? hollowButtonClasses : fullButtonClasses} ${roundClassName}`)
  const secondaryClasses = namesToClasses(
    hollow ? `formHollowButtonFont hollowActionColor` : `formFullButtonFont fullActionColor`,
  )

  return classNames(s.actionButton, primary ? primaryClasses : secondaryClasses)
}

export const SuccessActions: React.FC<SuccessActionsProps> = ({
  t,
  mobile,
  ticketsPdf,
  downloadTickets,
  className = '',
  calendarButtonText = t('ticketsThankYou.addToCalendar'),
  calendarLinks,
  addToCalendarClicked,
  openTicketsDownloadModal,
  formButtonStyle,
  downloadTicketsVisible,
  addToCalendarVisible,
}) => {
  if (!downloadTicketsVisible && !addToCalendarVisible) {
    return null
  }

  const handleTickets = () => {
    if (mobile) {
      openTicketsDownloadModal()
    } else {
      window.open(ticketsPdf)
      downloadTickets()
    }
  }

  return (
    <div className={className}>
      {downloadTicketsVisible && (
        <DownloadTickets
          t={t}
          className={classNames(getButtonClasses(formButtonStyle), s.primaryActionMargin)}
          onClick={handleTickets}
        />
      )}
      {addToCalendarVisible && (
        <AddToCalendar
          t={t}
          dataHook={DH.ADD_TO_CALENDAR}
          className={getButtonClasses(formButtonStyle, !downloadTicketsVisible)}
          buttonText={calendarButtonText}
          calendarLinks={calendarLinks}
          formButtonStyle={formButtonStyle}
          onClick={addToCalendarClicked}
        />
      )}
    </div>
  )
}

import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import {
  StaffMember,
  ServiceType,
  ServiceLocation,
  ServicePayment,
} from '@wix/bookings-uou-types';
import { ListSlotsResponse } from '@wix/ambassador-calendar-server/types';
import {
  SlotAvailability,
  LocationType,
  Location as SlotLocation,
} from '@wix/ambassador-availability-calendar/types';
import { CatalogData } from '../../api/types';
import {
  GetServiceResponse,
  ActionLabels,
  ScheduleStatus,
  PaymentOptions,
  Schedule,
  Header as FormHeader,
  Rate,
} from '@wix/ambassador-services-catalog-server/http';
import {
  mapServiceLocations,
  mapServicePayment,
} from '@wix/bookings-uou-mappers';
import { FormView } from '@wix/forms-ui/types';
import { TFunction } from '../../components/BookingsForm/controller';
import { createFormView } from './service-form.mapper';

export enum PaymentType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  PRICING_PLAN = 'PRICING_PLAN',
}

export type Location = {
  id?: string;
  name?: string;
  address?: string;
  locationType: LocationType;
};

export type Service = {
  name: string;
  rate: Rate;
  staffMembers: StaffMember[];
  location: Location;
  isPendingApprovalFlow: boolean;
  isWaitingListFlow: boolean;
  paymentTypes: PaymentType[];
  type: ServiceType;
  scheduleId: string;
  formSchema: FormView;
  formHeader: FormHeader;
  totalNumberOfSessions: number;
  payment: ServicePayment;
  videoConferenceProviderId?: string;
  firstSessionStart?: string;
  lastSessionEnd?: string;
  actionLabels?: ActionLabels;
};

export const mapCatalogServiceToService = ({
  catalogData,
  slotAvailability,
  t,
  pricingPlanDetails,
  listSlots,
}: {
  catalogData: CatalogData;
  slotAvailability: SlotAvailability;
  t: TFunction;
  pricingPlanDetails?: PaidPlans;
  listSlots?: ListSlotsResponse;
}): Service => {
  const activeSchedule = getActiveSchedule(catalogData.service);
  const scheduleId = activeSchedule!.id!;
  const serviceType = getServiceType(activeSchedule);

  const staffMembers = catalogData.staffMembers.map((staffMember) => {
    return {
      id: staffMember.id!,
      name: staffMember.name,
    };
  });

  const slotLocation: SlotLocation = slotAvailability?.slot?.location!;
  const serviceLocation: ServiceLocation =
    mapServiceLocations(activeSchedule)[0];

  const isCourse = serviceType === ServiceType.COURSE;

  const location = isCourse
    ? mapServiceLocationToLocation(serviceLocation)
    : mapSlotLocationToLocation(slotLocation);

  const paymentOptions: PaymentOptions =
    catalogData.service.service?.paymentOptions!;
  const paymentTypes: PaymentType[] = mapPaymentOptionsToPaymentTypes(
    paymentOptions,
    pricingPlanDetails,
  );

  const payment = mapServicePayment(catalogData.service);
  const formSchema = createFormView({
    catalogData,
    availability: slotAvailability,
    showAddress: serviceType === ServiceType.INDIVIDUAL,
    t,
  });
  const rate = activeSchedule.rate!;

  const formHeader = catalogData.service.form?.header!;

  return {
    name: catalogData.service.service!.info!.name!,
    formHeader,
    rate,
    payment,
    type: serviceType,
    staffMembers,
    paymentTypes,
    location,
    firstSessionStart: activeSchedule.firstSessionStart,
    lastSessionEnd: activeSchedule.lastSessionEnd,
    totalNumberOfSessions: listSlots?.slots?.length || 0,
    isPendingApprovalFlow:
      !!catalogData.service.service?.policy?.bookingsApprovalPolicy
        ?.isBusinessApprovalRequired,
    isWaitingListFlow:
      !!catalogData.service.service?.policy?.waitingListPolicy?.isEnabled,
    videoConferenceProviderId: activeSchedule?.conferenceProvider?.providerId,
    scheduleId,
    formSchema,
    actionLabels: catalogData.service.form?.actionLabels,
  };
};

export const getActiveSchedule = (service: GetServiceResponse): Schedule => {
  return (
    service?.schedules?.find(
      (schedule) => schedule.status === ScheduleStatus.CREATED,
    ) || service?.schedules?.[0]!
  );
};

export const getServiceType = (schedule: Schedule): ServiceType => {
  return (
    (schedule?.tags?.find(
      (tag: string) =>
        tag === ServiceType.COURSE ||
        tag === ServiceType.GROUP ||
        tag === ServiceType.INDIVIDUAL,
    ) as ServiceType) || ServiceType.INDIVIDUAL
  );
};

const mapPaymentOptionsToPaymentTypes = (
  paymentOptions: PaymentOptions,
  pricingPlanDetails?: PaidPlans,
): PaymentType[] => {
  const paymentTypes = [];
  if (paymentOptions?.wixPayOnline) {
    paymentTypes.push(PaymentType.ONLINE);
  }
  if (paymentOptions?.wixPayInPerson) {
    paymentTypes.push(PaymentType.OFFLINE);
  }
  if (pricingPlanDetails?.plans?.length && paymentOptions?.wixPaidPlan) {
    paymentTypes.push(PaymentType.PRICING_PLAN);
  }
  return paymentTypes;
};

export const isServiceTypeIsCourse = (serviceType: ServiceType) => {
  return serviceType === ServiceType.COURSE;
};

const mapServiceLocationToLocation = (location: ServiceLocation): Location => {
  return {
    id: location.businessLocation?.id,
    name: location.businessLocation?.name,
    ...{
      address:
        location?.locationText ??
        location?.businessLocation?.address?.formattedAddress,
    },
    locationType: location?.type as any,
  };
};

const mapSlotLocationToLocation = (location: SlotLocation): Location => {
  return {
    id: location?.id,
    name: location?.name,
    address: location?.formattedAddress,
    locationType: location!.locationType!,
  };
};

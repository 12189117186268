import React from 'react'
import {TextButton, TEXT_BUTTON_PRIORITY} from 'wix-ui-tpa/TextButton'
import classNames from 'classnames'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {Star} from '../../icons/Star'
import {StarFilled} from '../../icons/StarFilled'
import {useWidgetProps} from '../../../hooks/widget-props'
import {PersonalAgendaOrigin} from '../../../actions/schedule'
import {DH} from '../../../data-hooks'
import s from './my-agenda-button.scss'
import {classes as c} from './my-agenda-button.st.css'

export const MyAgendaButton = () => {
  const {bookmarksCount, t, viewPersonalAgenda} = useWidgetProps()
  const {isMobile} = useEnvironment()

  return (
    <TextButton
      data-hook={DH.MY_AGENDA_BUTTON}
      className={classNames(c.button, s.button, {[s.mobile]: isMobile})}
      prefixIcon={bookmarksCount ? <StarFilled className={s.starFilled} /> : <Star className={s.star} />}
      priority={TEXT_BUTTON_PRIORITY.primary}
      onClick={() => viewPersonalAgenda({origin: PersonalAgendaOrigin.Schedule})}
    >
      {t('myAgenda.viewButton', {count: bookmarksCount})}
    </TextButton>
  )
}

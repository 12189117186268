import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { isTruthy } from '../../utils/isTruthy';
import { DefaultSettingsAdapter } from '../PackagePicker/DefaultSettingsAdapter';

export class PlanListSettingsAdapter extends DefaultSettingsAdapter {
  getHiddenPlanIds(plans: PublicPlan[]): string[] {
    const visiblePlansValue = this.get('visiblePlans');
    const planIds = plans.map((plan) => plan.id).filter(isTruthy);
    if (visiblePlansValue.length > 0) {
      const visiblePlans = visiblePlansValue.split(',');
      return planIds.filter((id) => !visiblePlans.includes(id));
    } else {
      const hiddenPlans = this.get('hiddenPlans').split(',');
      return planIds.filter((id) => hiddenPlans.includes(id));
    }
  }

  hidePlan(id: string) {
    const visiblePlans = this.asArray('visiblePlans');
    this.set('visiblePlans', visiblePlans.filter((visibleId) => visibleId !== id).join(','));
  }

  unhidePlan(id: string) {
    const visiblePlans = this.asArray('visiblePlans');
    this.set('visiblePlans', visiblePlans.concat(id).join(','));
  }

  initializeVisiblePlans(plans: PublicPlan[]) {
    if (this.asArray('visiblePlans').length === 0) {
      const hiddenPlans = this.asArray('hiddenPlans');
      const visiblePlans = plans
        .filter((plan) => !hiddenPlans.includes(plan.id!))
        .map((plan) => plan.id)
        .join(',');
      this.set('visiblePlans', visiblePlans);
    }
  }
}

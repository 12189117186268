import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useTranslation } from 'yoshi-flow-editor-runtime';

import { st, classes } from './Validity.st.css';
import { getPlanValidityCycle } from '../../../../utils';

interface Props {
  plan: PublicPlan;
  highlighted: boolean;
}

export const Validity: React.FC<Props> = ({ plan, highlighted }) => {
  const { t } = useTranslation();
  return <span className={st(classes.duration, { highlighted })}>{getPlanValidityCycle(plan, t)}</span>;
};

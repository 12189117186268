import React from 'react';
import { classes } from './DailyLayoutBody.st.css';
import TimePicker from './TimePicker/TimePicker';
import DatePicker from '../DatePicker/DatePicker';
import SectionHeader from '../SectionHeader/SectionHeader';
import ErrorNotification from '../ErrorNotification/ErrorNotification';
import { DailyLayoutBodyViewModel } from '../../ViewModel/dailyLayoutBodyViewModel/dailyLayoutBodyViewModel';

export interface DailyLayoutBodyProps {
  viewModel: DailyLayoutBodyViewModel;
  errorNotificationText: string;
}

const DailyLayoutBody: React.FC<DailyLayoutBodyProps> = ({
  viewModel: { bodyTitle, datePickerViewModel, timePickerViewModel },
  errorNotificationText,
}) => {
  return (
    <div data-hook="daily-layout-body">
      <SectionHeader title={bodyTitle} />
      {errorNotificationText ? (
        <ErrorNotification errorText={errorNotificationText} />
      ) : null}
      <div className={classes.root}>
        <DatePicker datePickerViewModel={datePickerViewModel!} />
        <TimePicker viewModel={timePickerViewModel!} />
      </div>
    </div>
  );
};

export default DailyLayoutBody;

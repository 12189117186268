import * as React from 'react';
import { Tags } from 'wix-ui-tpa/Tags';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../../context/running-environment-context';
import { st, classes } from './TagsCategories.st.css';
import { CategoriesProps } from '../Categories';
import { SIZE, SKIN } from 'wix-ui-tpa/dist/src/components/Tags/constants';

interface TagsCategoriesProps
  extends RunningEnvironmentProps,
    CategoriesProps {}

interface CategoryTagItem extends TagItem {
  id: number;
}

// temporary remove once https://github.com/wix/wix-ui-tpa/pull/308 is merged
interface TagItem {
  title: string;
  checked?: boolean;
  disabled?: boolean;
  value: string;
  link?: string;
  rel?: string;
}

class TagsCategoriesComponent extends React.PureComponent<TagsCategoriesProps> {
  constructor(props: TagsCategoriesProps) {
    super(props);
    this.onCategorySelected = this.onCategorySelected.bind(this);
  }

  onCategorySelected(newSelectedCategoryIndex) {
    this.props.onCategorySelected(newSelectedCategoryIndex);
  }

  render() {
    const { categoriesViewModel, selectedCategoryIndex, categoriesItems } =
      this.props;
    const { alignment } = categoriesViewModel;
    const items: CategoryTagItem[] = categoriesItems.map((category, index) => ({
      title: category.title,
      checked: index === selectedCategoryIndex,
      value: category.id,
      id: index,
    }));

    const tagsProps = {
      items,
      onClick: (item: CategoryTagItem) => {
        this.onCategorySelected(Number(item.id));
      },
      skin: SKIN.light,
      size: SIZE.medium,
      alignment,
    };

    // @ts-ignore
    return <Tags {...tagsProps} className={st(classes.root, {}, tagsProps)} />;
  }
}

export const TagsCategories = withRunningEnvironmentContext(
  TagsCategoriesComponent,
);

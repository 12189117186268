export function toBase64Url(base64: string) {
  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

export function fromBase64Url(base64url: string) {
  return base64url.replace(/-/g, '+').replace(/_/g, '/');
}

export function encodeBase64Url<T>(input: T) {
  return toBase64Url(encodeBase64(input));
}

export function decodeBase64Url(input: string) {
  return decodeBase64(fromBase64Url(input));
}

export function decodeBase64(input: string) {
  return JSON.parse(decode(input));
}

export function encodeBase64<T>(input: T) {
  return encode(JSON.stringify(input));
}

export function encode(str: string): string {
  return btoa(encodeURIComponent(str).replace(/%[0-9A-F]{2}/g, percentToByte));
}

export function decode(str: string): string {
  return decodeURIComponent(Array.from(atob(str), byteToPercent).join(''));
}

function percentToByte(p: string) {
  return String.fromCharCode(parseInt(p.slice(1), 16));
}

function byteToPercent(b: string) {
  return `%${`00${b.charCodeAt(0).toString(16)}`.slice(-2)}`;
}

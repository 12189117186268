import Container_DefaultAreaSkinComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/DefaultAreaSkin/DefaultAreaSkin.skin';


const Container_DefaultAreaSkin = {
  component: Container_DefaultAreaSkinComponent
};


export const components = {
  ['Container_DefaultAreaSkin']: Container_DefaultAreaSkin
};


// temporary export
export const version = "1.0.0"

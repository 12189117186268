import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { SubmissionResponse } from '@wix/forms-ui/types';
import { widgetDefaults } from '../../../../utils/bi/consts';
import { mapSubmissionToFormInfo } from '../../../../utils/mappers/form-submission.mapper';

export type OnSubmit = (submission: SubmissionResponse) => void;

export function createOnSubmitAction({
  context: { wixSdkAdapter, formApi },
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): OnSubmit {
  return async (submissionResponse) => {
    const [state] = getControllerState();
    const { service, slotAvailability } = state;
    if (submissionResponse.state.valid) {
      const formInfo = mapSubmissionToFormInfo(
        submissionResponse.submission,
        service,
      );
      const bookingResponse = await formApi.book({
        service,
        formInfo,
        slotAvailability,
      });
      return wixSdkAdapter.navigateToBookingsCheckout(
        bookingResponse.booking!,
        widgetDefaults.pageName,
      );
    }
  };
}

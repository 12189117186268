import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { FormContext } from '../../../../utils/context/contextFactory';

export type OnLogin = () => void;

export function createOnLoginAction({
  getControllerState,
  context: { wixSdkAdapter, formApi },
}: ActionFactoryParams<FormState, FormContext>): OnLogin {
  return async () => {
    const [, setState] = getControllerState();
    try {
      const user = await wixSdkAdapter.promptUserLogin();

      if (user) {
        const memberDetails = await formApi.getMemberDetails(user.id);
        setState({ memberDetails });
      }
    } catch (e) {}
  };
}

import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  ISiteTextPreset,
  IWixStyleFont,
} from '@wix/yoshi-flow-editor/tpa-settings'

type IStylesParams = {
  backgroundColor: StyleParamType.Color
  headerTextFont: StyleParamType.Font
  scheduleItemDividerWidth: StyleParamType.Number
}

const getDefaultFont = (name: keyof ISiteTextPreset, options: Partial<IWixStyleFont> = {}) => {
  return ({textPresets}: {textPresets: ISiteTextPreset}) => {
    const {value: _, ...font} = wixFontParam(name, options)({textPresets})
    return font as IWixStyleFont
  }
}

export default createStylesParams<IStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  scheduleItemDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
})

import {BI_ORIGINS, scrollElementIntoView, focusElement, EventDetailsAlignment} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {DH, hookToAttributeSelector} from '../../constants/data-hooks'
import {Members} from '../members'
import {SocialShareWithTitle} from '../social-share/with-title'
import {Subtitle} from '../subtitle'
import cs from '../classnames.scss'
import {AboutSection} from './about-section'
import {getAlignmentClass} from './alignment'
import s from './event-details.scss'
import {FullDate} from './full-date'
import {FullLocation} from './full-location'
import {Header} from './header'
import {EventDetailsProps} from './interfaces'
import {MembershipOffers} from './membership-offers'
import {RecurringDatesButton} from './recurring-dates-button'
import {RecurringDatesCount} from './recurring-dates-count'
import {RegistrationButton} from './registration-button'
import {TicketsPicker} from './tickets-picker'
import {Map} from './map'
import {Schedule} from './schedule'

export const EventDetails = ({
  eventTitle,
  texts,
  contentAlignment,
  ticketedEvent,
  membersVisible,
  mapVisible,
  membersDividerVisible,
  aboutSectionVisible,
  showPaidPlans,
  t,
  hasTickets,
  socialShareVisible,
  scheduleVisible,
  detailsPageLoaded,
  handleRSVP,
  moreDatesVisible,
  moreDatesButtonVisible,
}: EventDetailsProps) => {
  React.useEffect(() => {
    detailsPageLoaded()
    focusElement({selector: hookToAttributeSelector(DH.eventDetails), preventScroll: true})
  }, [])

  const rsvp = () => handleRSVP()
  const contentCentered = contentAlignment === EventDetailsAlignment.CENTER

  return (
    <section data-hook={DH.eventDetails} aria-label={eventTitle}>
      <div className={classNames(s.background, cs.evBackgroundColor)} />
      {/* TODO: header is not yet fully responsive (i.e. image). */}
      <Header onClickRegistrationButton={ticketedEvent ? scrollToTicketsPicker : rsvp} />
      <div className={s.container} data-hook={DH.eventDetailsContent}>
        <div className={classNames(s.content, getAlignmentClass(contentAlignment))}>
          <Subtitle dataHook={DH.subtitleTimeAndLocation} text={texts.timeAndLocationTitleText} />
          {moreDatesVisible && <RecurringDatesCount />}
          <div className={classNames(s.timeAndLocationContainer, {[s.centered]: contentCentered})}>
            <div className={classNames(s.timeAndLocation, cs.evTextFont, cs.evTextColor)}>
              <FullDate />
              <FullLocation />
            </div>
            {moreDatesButtonVisible && <RecurringDatesButton className={s.recurringDates} />}
          </div>
          {moreDatesButtonVisible && <Divider />}
          {membersVisible ? (
            <>
              <Subtitle dataHook={DH.MEMBERS_TITLE} text={texts.guestsTitleText} compact />
              <div className={s.membersContainer}>
                <Members />
              </div>
              {membersDividerVisible && <Divider />}
            </>
          ) : null}
          {aboutSectionVisible && <AboutSection compact={membersVisible} />}
          {scheduleVisible && <Schedule t={t} />}
          {!ticketedEvent && <RegistrationButton onClick={rsvp} secondary />}
          {showPaidPlans && <MembershipOffers />}
          {hasTickets && <TicketsPicker t={t} />}
        </div>
        {mapVisible && (
          <div className={s.mapSpacing}>
            <Map />
          </div>
        )}
        {socialShareVisible && (
          <div className={classNames(s.content, s.sectionSpacing, getAlignmentClass(contentAlignment))}>
            <SocialShareWithTitle page={BI_ORIGINS.EVENT_DETAILS} t={t} />
          </div>
        )}
        <div className={s.safariMarginFix} />
      </div>
    </section>
  )
}

const Divider = () => <div className={classNames(s.divider, cs.evTicketDividerColor, cs.evTicketDividerSize)} />

const scrollToTicketsPicker = () => {
  scrollElementIntoView(hookToAttributeSelector(DH.TICKETS_PICKER))
}

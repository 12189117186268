import React from 'react'
import classNames from 'classnames'
import X from 'wix-ui-icons-common/X'
import {DH} from '../data-hooks'
import {getWixAdsHeight} from '../../../utils/ui'
import {ModalProps} from './interfaces'
import s from './modal.scss'

export const Modal: React.FC<ModalProps> = ({children, t, closeModal}) => {
  const bannerHeight = getWixAdsHeight()

  return (
    <div className={s.container} style={{marginTop: bannerHeight, paddingBottom: 52 + bannerHeight}}>
      <button
        className={classNames(s.closeButtonX, 'a11yOutline')}
        data-hook={DH.CLOSE_BUTTON}
        aria-label={t('accessibility:a11y.close')}
        onClick={() => closeModal()}
      >
        <X className={s.iconX} />
      </button>
      {children}
    </div>
  )
}

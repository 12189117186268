import React from 'react';
import { classes } from './ErrorNotification.st.css';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/src/assets/icons/Error.svg';

export interface ErrorNotificationProps {
  errorText?: string;
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ errorText }) => {
  return (
    <SectionNotification
      type="error"
      className={classes.root}
      data-hook="error-notification"
    >
      <SectionNotification.Icon icon={<Error />} />
      <SectionNotification.Text>{errorText}</SectionNotification.Text>
    </SectionNotification>
  );
};

export default ErrorNotification;

import type { ApplicationContextOptions, ComponentRef, EditorSDK } from '@wix/platform-editor-sdk'
import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { namespace, WidgetWixCodeSdkHandlers, WidgetWixCodeSdkWixCodeApi } from '..'

/**
 * This is SDK Factory.
 * Expose your Corvid API
 */
export function WidgetSdkFactory({
	platformUtils,
}: WixCodeApiFactoryArgs<never, never, WidgetWixCodeSdkHandlers>): { [namespace]: WidgetWixCodeSdkWixCodeApi } {
	let _editorSdk: EditorSDK
	let _componentRef: ComponentRef

	const getEditorSdk = async (): Promise<EditorSDK> => {
		if (!_editorSdk) {
			_editorSdk = await initEditorSdk()
		}

		return _editorSdk
	}

	const initEditorSdk = async (): Promise<EditorSDK> => {
		const channel = new MessageChannel()

		const windowSdk = platformUtils.wixCodeNamespacesRegistry.get('window')
		const wixLocation = platformUtils.wixCodeNamespacesRegistry.get('location')

		const url = new URL(wixLocation.url)
		const applicationIdParam = url.searchParams.get('applicationId')
		const appDefinitionIdParam = url.searchParams.get('appDefinitionId')

		if (!applicationIdParam || !appDefinitionIdParam) {
			throw new Error('Could not find application id params')
		}

		const applicationId = Number(url.searchParams.get('applicationId'))

		if (isNaN(applicationId)) {
			throw new Error('Could not parse applicationId')
		}

		const sdkScriptSrc = url.searchParams.get('sdkScriptSrc')
		if (!sdkScriptSrc) {
			throw new Error('Could not find script source')
		}

		const contextOptions: ApplicationContextOptions = {
			applicationId,
			appDefinitionId: appDefinitionIdParam,
		}

		channel.port1.onmessage = (ev) => {
			windowSdk.postMessage(ev.data, undefined, undefined, [...ev.ports])
		}

		self.importScripts(sdkScriptSrc)

		const editorSDK = self.editorSDK
		await editorSDK.__initWithTarget(channel.port2, [], '')

		return editorSDK.getBoundedSDK(contextOptions)
	}

	const getComponentRef = () => {
		if (!_componentRef) {
			const wixLocation = platformUtils.wixCodeNamespacesRegistry.get('location')

			const url = new URL(wixLocation.url)
			const componenRefSerialized = url.searchParams.get('componentRef')
			if (!componenRefSerialized) {
				throw new Error('could not find serialized component ref')
			}
			_componentRef = JSON.parse(componenRefSerialized)
		}

		return _componentRef
	}

	const getComponentRefConfig = async () => {
		const editorSdk = await getEditorSdk()

		const controllerRef = getComponentRef()
		const controllerData = await editorSdk.document.controllers.getData('token', {
			controllerRef,
		})

		return controllerData.config
	}

	const getProps = async () => {
		const componetRefConfig = await getComponentRefConfig()
		return componetRefConfig.props || {}
	}

	const setProps = async (props: any) => {
		const editorSdk = await getEditorSdk()

		const config = await getComponentRefConfig()
		config.props = props

		await editorSdk.controllers.saveConfiguration('token', {
			controllerRef: getComponentRef(),
			config,
		})

		await editorSdk.application.livePreview.refresh('token', {
			source: 'AFTER_PROP_UPDATE',
			shouldFetchData: false,
		})
	}

	return {
		[namespace]: {
			setProps,
			getProps,
		},
	}
}

import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { HeaderViewModel } from '../../ViewModel/headerViewModel/headerViewModel';
import { st, classes } from './Header.st.css';
import Filters from './Filters/Filters';

export type HeaderProps = {
  viewModel: HeaderViewModel;
};

const Header: React.FC<HeaderProps> = ({ viewModel }) => {
  const { isMobile, isRTL } = useEnvironment();
  const { title, subtitle, isSubtitleVisible, isFiltersVisible, filters } =
    viewModel;
  const shouldShowSubtitle = isSubtitleVisible && subtitle;

  return (
    <div data-hook="header" className={st(classes.root, { isRTL, isMobile })}>
      <Text
        data-hook="header-title"
        className={classes.title}
        tagName={AccessibilityHtmlTags.MainHeading}
      >
        {title}
      </Text>
      {shouldShowSubtitle ? (
        <Text
          data-hook="header-subtitle"
          className={classes.subtitle}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {subtitle}
        </Text>
      ) : null}
      {isFiltersVisible ? <Filters filterViewModels={filters} /> : null}
    </div>
  );
};

export default Header;

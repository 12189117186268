export enum PaymentDataHooks {
  PAYMENT_WRAPPER = 'payment-wrapper',
  TITLE = 'payment-title',
  DIVIDER = 'payment-divider',
  PAYMENT_OPTIONS = 'payment-options',
  PAYMENT_OPTION = 'payment-option',
  WARNING = 'payment-warning',
  CUSTOM_PRICE = 'payment-custom-price',
  PRICE_SUMMARY = 'payment-price-summary',
  MEMBERSHIP = 'payment-membership',
  MEMBERSHIP_VALIDATION = 'payment-membership-validation',
  MEMBERSHIP_CREDIT = 'payment-membership-credit',
  SUMMARY_SINGLE_SESSION = 'payment-summary-single-session-label',
  TOTAL = 'payment-total-label',
  PROMO_CODE_TEXT_BUTTON = 'payment-promo-code-text-button',
  PROMO_CODE_INPUT = 'payment-promo-code-input',
}

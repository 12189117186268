import { TFunction } from '../../components/BookingsForm/controller';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormApi } from '../../api/FormApi';

export type FormContext = {
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
};

export const createFormContext = ({
  t,
  settings,
  wixSdkAdapter,
  formApi,
}: {
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
}): FormContext => {
  return {
    t,
    settings,
    wixSdkAdapter,
    formApi,
  };
};

import {State} from '../types'

export const hasAgreedWithPolicies = (state: State) => state.policies.agreed

export const hasPolicies = (state: State) => Boolean(getPolicies(state)?.length)

export const getPolicies = (state: State) => state.policies.policies

export const getAgreementToken = (state: State) => state.policies.policyAgreementToken

export const shouldShowAlert = (state: State) => state.policies.showAlert

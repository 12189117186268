import FiveGridLine_SolidLineComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/SolidLine/SolidLine.skin';


const FiveGridLine_SolidLine = {
  component: FiveGridLine_SolidLineComponent
};


export const components = {
  ['FiveGridLine_SolidLine']: FiveGridLine_SolidLine
};


// temporary export
export const version = "1.0.0"

import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { Plan } from '../Plan';
import { classes } from './PlanList.st.css';
import { useAdaptedSettings } from '../../../../services/settings-adapter';

interface Props {
  plans: PublicPlan[];
  selectPlan: (plan: PublicPlan) => void;
  showDemoHighlight?: boolean;
  isMobile?: boolean;
}

export const PlansList: React.FC<Props> = ({ plans, showDemoHighlight, selectPlan, isMobile }) => {
  const settings = useAdaptedSettings();

  const highlightedPlanId = settings.getHighlightedPlanId(plans, showDemoHighlight);
  const orderedPlans = settings.getOrderedPlans(plans);

  const recurringPlansExist = orderedPlans.filter((p) => p.paymentOptions?.recurring).length > 0;
  const freeTrialDaysExist = orderedPlans.filter((p) => p.paymentOptions?.freeTrialDays).length > 0;
  const anyBenefits = !!orderedPlans.find((p) => p.details?.benefits?.length);

  return (
    <ul tabIndex={-1} className={classes.container}>
      {orderedPlans.map((plan) => (
        <li key={plan.id} tabIndex={1}>
          <Plan
            anyBenefits={anyBenefits}
            highlighted={highlightedPlanId === plan.id}
            plan={plan}
            recurringPlansExist={recurringPlansExist}
            freeTrialDaysExist={freeTrialDaysExist}
            isMobile={isMobile}
            onClick={() => selectPlan(plan)}
          />
        </li>
      ))}
    </ul>
  );
};

import { ServicePayment, OfferedAsType } from '@wix/bookings-uou-types';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';

export const isOfferedAsOneTime = (servicePayment: ServicePayment) => {
  return servicePayment.offeredAs.includes(OfferedAsType.ONE_TIME);
};

export const isOfferedAsOneTimeOnly = (servicePayment: ServicePayment) => {
  return (
    servicePayment.offeredAs.length === 1 && isOfferedAsOneTime(servicePayment)
  );
};

export const isOfferedAsPricingPlan = (servicePayment: ServicePayment) => {
  return servicePayment.offeredAs.includes(OfferedAsType.PRICING_PLAN);
};

export const isOfferedAsPricingPlanOnly = (servicePayment: ServicePayment) => {
  return (
    servicePayment.offeredAs.length === 1 &&
    servicePayment.offeredAs.includes(OfferedAsType.PRICING_PLAN)
  );
};

export const isFixedPrice = (servicePayment: ServicePayment) => {
  return servicePayment.paymentDetails.price > 0;
};

export const isCustomPrice = (servicePayment: ServicePayment) => {
  return !!servicePayment.paymentDetails.priceText;
};

export const isFreeService = (servicePayment: ServicePayment) => {
  return !isFixedPrice(servicePayment) && !isCustomPrice(servicePayment);
};

export const hasPurchesedPlans = (pricingPlanDetails: PaidPlans) => {
  return pricingPlanDetails?.plans!.length > 0;
};

export const hasMoreParticipantsThenCredit = (
  creditRemain: number,
  numberOfParticipants: number,
) => {
  return creditRemain < numberOfParticipants;
};

export const isServiceHavePricingPlans = (servicePayment: ServicePayment) => {
  return servicePayment.pricingPlanInfo?.pricingPlans?.length! > 0;
};

import type { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import type { WidgetProps } from 'yoshi-flow-editor-runtime';
import { V1OrdersOrder } from '@wix/ambassador-membership-api/http';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';

export enum TabState {
  REGULAR = 'regular',
  HIGHLIGHTED = 'highlighted',
}

export interface TextData {
  ['button-text']?: string;
  ['disclaimer-text']?: string;
  ['free-trial-button-text']?: string;
  ['page-title-text']?: string;
  ['ribbon-text']?: string;
}

export enum PopupEnum {
  checkoutPreview = 'CHECKOUT_PREVIEW',
}

export enum MessageCode {
  PURCHASE_LIMIT_ERROR = 1,
  UNKNOWN_ERROR = 2,
  CHECKOUT_DEMO = 3,
  START_DATE_CHANGED = 4,
  START_DATE_UPDATE_FAILED = 5,
  PLAN_NOT_FOUND = 6,
  CHECKOUT_PAYLOAD_DECODE_FAILED = 7,
  STATUS_PAYLOAD_DECODE_FAILED = 8,
  INTEGRATION_DATA_DECODE_FAILED = 9,
}

interface VerticalStatusContent {
  titleText: string;
  contentText?: string;
  buttonText: string;
}

interface BiOptions {
  referralInfo: string;
  referralId?: string;
}

export interface NavigateToSectionProps {
  appDefinitionId: string;
  sectionId: string;
  queryParams?: Object;
}

export interface IntegrationData {
  planIds?: string[];
  navigateToSectionProps?: NavigateToSectionProps;
  navigateToPageProps?: string;
  verticalStatusContent?: VerticalStatusContent;
  biOptions?: BiOptions;
  restrictedPageId?: string;
  minStartDate?: string;
  maxStartDate?: string;
}

export interface CheckoutData {
  integrationData: IntegrationData;
  planId: string;
  orderId?: string;
}

export interface PurchaseData {
  checkoutData: CheckoutData;
  orderId: string;
  snapshotId: string;
  freeTrialDays?: number;
}

export interface StatusData {
  purchaseData: PurchaseData;
  successful: boolean;
  error?: string;
  startDate?: string;
  planName?: string;
  ownerDemo?: boolean;
}

export type UserData = Pick<IUser, 'id' | 'loggedIn' | 'role' | 'instance'>;

export type SubPage =
  | { name: 'list'; integrationData: IntegrationData }
  | { name: 'checkout'; checkoutData: CheckoutData }
  | { name: 'status'; statusData: StatusData };

export interface CommonProps {
  subPage: SubPage;
  message?: MessageCode;
}

export interface BaseProps {
  tabState: TabState;
  instance?: string;
  appInstanceId: string;
  metaSiteId?: string;
}

export interface ListProps {
  plans: PublicPlan[];
  popup: PopupEnum | null;
  selectPlan(plan: PublicPlan): void;
  hidePopup(): void;
}

export interface CheckoutProps {
  user: UserData;
  userEmail?: string;
  selectedPlan: PublicPlan;
  order?: V1OrdersOrder;
  loginOnCheckout(): void;
  signupOnCheckout(): void;
  logout(): void;
}

export interface StatusProps {
  navigateToPlanList(): void;
  navigateBackToTPA(): void;
}

export type AppProps = CommonProps & BaseProps & ListProps & CheckoutProps & StatusProps;

export type AppWidgetProps = WidgetProps<AppProps>;

export interface IEvents {
  tabState: TabState;
}

import React, { FC, useRef } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { st, classes } from './Widget.st.css';
import { FormControllerActions } from '../Actions/actions';
import { FormViewModel } from '../ViewModel/viewModel';
import { FormActionsProvider } from '../Hooks/useFormActions';
import { FormRef, SubmissionResponse } from '@wix/forms-ui/types';
import FormInputs from './FormInputs/FormInputs';
import { Login } from './Login/Login';
import { FormState } from '../../../utils/state/initialStateFactory';
import Details from './Details/Details';
import { Header } from './Header/Header';
import BookButton from './BookButton/BookButton';
import Payment from './Payment/Payment';

export type FormComponentActions = { submitForm?: () => SubmissionResponse };
export type FormActions = FormControllerActions & FormComponentActions;

export type ControllerProps = {
  actions: FormControllerActions;
} & FormState &
  FormViewModel;

const Widget: FC<WidgetProps<ControllerProps>> = ({
  service,
  paymentViewModel,
  actions,
  slotAvailability,
  businessInfo,
  memberDetails,
}) => {
  const formRef = useRef<FormRef>();
  const submitForm = () => formRef?.current?.submit();
  const showLogin = !memberDetails;

  const slot = slotAvailability?.slot!;
  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;

  return (
    <FormActionsProvider value={{ ...actions, submitForm }}>
      <div className={st(classes.root, {})}>
        <div className={classes.backButton}>Back</div>
        <div className={classes.body}>
          <div className={classes.formWrapper}>
            <Header {...service.formHeader} />
            {showLogin ? <Login /> : null}
            <FormInputs
              formSchema={service.formSchema}
              formRef={formRef}
              memberDetails={memberDetails}
            />
          </div>

          <div className={classes.sidebar}>
            <Details
              service={service}
              startDate={slot.startDate!}
              endDate={slot.endDate!}
              dateRegionalSettingsLocale={dateRegionalSettingsLocale}
            />
            <Payment viewModel={paymentViewModel} />
            <BookButton service={service} />
          </div>
        </div>
      </div>
    </FormActionsProvider>
  );
};

export default Widget;

import { runAndWaitForApproval } from '../wrappers/transactions';
import * as appState from '../services/applicationState';
import enforceSequentiality from '../enforceSequentiality';
import * as applicationState from '../applicationState';
import { log, toMonitored } from '../../utils/monitoring';
import { getAppDefinitions } from '@wix/members-area-app-definitions';
import { addApplications, createConnectionConfigs } from '../platform-api/addApplications';
import * as routersWrapper from '../wrappers/routers';
import * as pagesService from '../services/pages';
import { addMinHeight } from '../services/pages';
import { getTranslationFunction } from '../../i18n';
import * as pagesWrapper from '../wrappers/pages';
import * as routersService from '../services/routers';
import { removeMembersAreaPageByPageId } from '../platform-api/removeMembersAreaPage';
import { setHorizontalLayout, setSidebarLayout } from '../platform-api/layouts';
import * as membersIntegrationApi from '../services/integration';
import { getProfileType, setProfileType } from '../services/profile';
import { refreshMembersAreaApps } from '../services/members-area';

const getUniqueNewPublicPageUriSeo = async (_editorSDK, initialPrefix = 'blank') => {
  let pageUriSeo;

  const routers = await routersWrapper.getAll(_editorSDK);
  const currentPageUris = Object.keys(
    routers.find((router) => router.config.type === 'public').config.patterns || {},
  ).map((pattern) => pattern.split('/{userName}/')[1]);

  let counter = 1;
  let isUnique;

  do {
    pageUriSeo = `${initialPrefix}-${counter}`;
    isUnique = !currentPageUris.includes(pageUriSeo);
    counter++;
  } while (!isUnique && counter < 1000);

  return pageUriSeo;
};

const createBlankPage = async (editorSDK, isPrivate = true) => {
  let pageRef;

  try {
    const t = await getTranslationFunction(editorSDK);
    const pageUriSEO = isPrivate ? undefined : await getUniqueNewPublicPageUriSeo(editorSDK);
    const pageTitle = isPrivate ? t('Pages_New_Private_Page_Title') : t('Pages_New_Public_Page_Title');
    pageRef = await pagesWrapper.addPage({ editorSDK, pageTitle, pageUriSEO });
    await addMinHeight([{ pageRef }], editorSDK);
    if (!isPrivate) {
      await pagesWrapper.updatePageData({
        editorSDK,
        pageRef,
        pageData: { pageSecurity: { requireLogin: false }, hidePage: false },
      });
    }

    const createdPage = await pagesWrapper.getPageData({ editorSDK, pageRef });
    const routers = (await routersService.getMembersAreaRouters(editorSDK)) || { publicRouter: {}, privateRouter: {} };
    createdPage.pageRef = pageRef;
    const apps = [
      {
        appDefinitionId: createdPage.managingAppDefId,
        pageId: '',
        social: !isPrivate,
        showInLoginMenu: true,
        showInMemberMenu: true,
        loginMenuTitle: false,
      },
    ];
    const connectionConfigs = createConnectionConfigs({ applications: apps, pages: [createdPage], routers });
    await pagesService.connectPagesToMembers({ editorSDK, pages: connectionConfigs });
    await pagesService.setStateForPages(editorSDK);
  } catch (e) {
    log('Add custom page failed', {
      tags: { reason: e.toString() + '\n' + e.stack, isPrivate, pageRefAdded: !!pageRef },
    });
  }
};

const maybeAddApplications = (applications, shouldNavigate, options) => {
  const editorSDK = appState.getEditorSDK();

  return enforceSequentiality('maybeAddApplications', async () => {
    const isReady = await applicationState.isApplicationReady(editorSDK);
    if (!isReady) {
      console.warn('Members Area installation was corrupted so the integrations pages will not be added');
      log('Skipping addApplications as the application is not ready and probably already deleted');
      return;
    }
    const applicationDefinitions = await getAppDefinitions({ applications, editorSDK });

    return toMonitored('editorApi.addApplications', () =>
      addApplications({ editorSDK, applications: applicationDefinitions, shouldNavigate, biData: options?.biData }),
    );
  });
};

const refreshPageState = (editorSDK) => pagesService.setStateForPages(editorSDK);

export const createPublicApi = (editorSDK) => {
  const transaction =
    (action) =>
    (...props) =>
      runAndWaitForApproval(editorSDK, () => action(...props));

  return {
    addApplications: transaction(maybeAddApplications),
    getMembersPageRef: ({ appDefinitionId, appPageId } = {}) => {
      return routersWrapper.findPageRefByAppData(editorSDK, appDefinitionId, appPageId);
    },
    removeMembersAreaPage: (pageId, appDefinitionId) =>
      enforceSequentiality('editorApi.removeMembersAreaPage', () =>
        toMonitored(
          'editorApi.removeMembersAreaPage',
          transaction(() => removeMembersAreaPageByPageId({ pageId, appDefinitionId, editorSDK })),
        ),
      ),
    setHorizontalLayout: () =>
      enforceSequentiality('editorApi.setHorizontalLayout', () =>
        toMonitored(
          'editorApi.setHorizontalLayout',
          transaction(() => setHorizontalLayout(editorSDK)),
        ),
      ),
    setSidebarLayout: () =>
      enforceSequentiality('editorApi.setSidebarLayout', () =>
        toMonitored(
          'editorApi.setSidebarLayout',
          transaction(() => setSidebarLayout(editorSDK)),
        ),
      ),
    _getIsResponsiveEditor: () =>
      enforceSequentiality('_getIsResponsiveEditor', () => appState.getIsResponsiveEditor()),
    handleVerticalDeletion: transaction((verticalAppDefId) =>
      membersIntegrationApi.handleVerticalDeletion(verticalAppDefId, editorSDK),
    ),
    registerMembersAreaApps: (applications, verticalAppDefId, applicationsOptions) =>
      membersIntegrationApi.registerMembersAreaApps(applications, verticalAppDefId, editorSDK, applicationsOptions),
    installRegisteredApps: transaction((verticalAppDefId, options) =>
      membersIntegrationApi.installRegisteredApps(verticalAppDefId, editorSDK, options),
    ),
    getRegisteredApps: () => membersIntegrationApi.getRegisteredApps(editorSDK),
    addCustomPage: transaction((isPrivate) => createBlankPage(editorSDK, isPrivate)),
    refreshPageState: () => refreshPageState(editorSDK),
    getProfileType: () => getProfileType(editorSDK),
    setProfileType: transaction((type) => setProfileType(editorSDK, type)),
    refreshRouters: () => routersWrapper.refreshRouters(editorSDK),
    refreshMembersAreaApps: () => refreshMembersAreaApps(editorSDK),
    registerAdditionalWidgets: () => {},
    getAdditionalWidgets: () => {},
    installAdditionalWidgets: () => {},
  };
};

import { FormField } from '@wix/ambassador-services-catalog-server/types';
import { CatalogData } from '../../api/types';
import {
  DisplayPropertiesFieldOption,
  V2Rule,
  Operation,
} from '@wix/ambassador-wix-form-builder-web/types';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  createAddressField,
  createFormField,
  getFieldType,
} from './service-form-field.mapper';
import { TFunction } from '../../components/BookingsForm/controller';
import { FormFieldViewInfoFieldType, FormView } from '@wix/forms-ui/types';
import { emailRegex, phoneRegex } from '@wix/wix-js-validations';
import { BookingRequestKeyMappings } from './form-submission.mapper';

export const createFormView = ({
  catalogData,
  availability,
  showAddress,
  t,
}: {
  catalogData: CatalogData;
  t: TFunction;
  showAddress?: boolean;
  availability?: SlotAvailability;
}): FormView => {
  const form = catalogData.service.form!;
  const { customFields = [] } = form;

  const { isSMSReminderEnabled } = catalogData.businessInfo;
  const smsReminderFieldId = 'sms-reminder-id';
  const phoneFieldType = isSMSReminderEnabled
    ? FormFieldViewInfoFieldType.PHONE_COUNTRY_CODE
    : FormFieldViewInfoFieldType.PHONE;

  const maxParticipantsPerBooking =
    catalogData?.service?.service?.policy?.maxParticipantsPerBooking ?? 0;

  const availableSpots = availability?.openSpots ?? 0;
  const maxNumberOfParticipants = getMaxNumberOfParticipants({
    availableSpots,
    maxParticipantsPerBooking,
  });
  const numberOfParticipantsDefaultValue = '1';

  const fields = [
    createFormField({
      field: form.name!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.FIRST_NAME,
      },
      fieldType: FormFieldViewInfoFieldType.TEXT,
    }),
    createFormField({
      field: form.email!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.EMAIL,
      },
      fieldType: FormFieldViewInfoFieldType.EMAIL,
      validations: {
        pattern: emailRegex.source.toString(),
      },
    }),
    createFormField({
      field: form.phone!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.PHONE,
      },
      fieldType: phoneFieldType,
      validations: isSMSReminderEnabled
        ? { pattern: phoneRegex.source.toString() }
        : {},
    }),
    ...(isSMSReminderEnabled
      ? [
          createFormField({
            field: {
              label: t('app.booking-form.fields.sms-reminder.label'),
              fieldId: smsReminderFieldId,
            },
            fieldType: FormFieldViewInfoFieldType.CHECKBOX,
          }),
        ]
      : []),
    ...(maxNumberOfParticipants > 1
      ? [
          createFormField({
            field: form.numberOfParticipants!,
            fieldType: FormFieldViewInfoFieldType.DROP_DOWN,
            bookingsKeyMapping: {
              key: BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
            },
            displayProperties: {
              options: getNumberOfParticipantsOptions(maxNumberOfParticipants),
              defaultValue: numberOfParticipantsDefaultValue,
            },
          }),
        ]
      : []),
    ...(showAddress && form.address ? [createAddressField(form.address!)] : []),
    ...customFields.map((field: FormField) =>
      createFormField({
        field,
        fieldType: getFieldType(field.valueType!),
      }),
    ),
  ];

  const rule: V2Rule[] = [
    {
      enabled: isSMSReminderEnabled,
      condition: { eq: { fieldKey: smsReminderFieldId, value: true } },
      actions: [
        { fieldKeys: [form.phone!.fieldId!], operation: Operation.REQUIRED },
      ],
    },
  ];

  return {
    formId: form.id,
    fields,
    formViewInfo: {
      rule,
    },
  };
};

const getNumberOfParticipantsOptions = (
  maxOption: number,
): DisplayPropertiesFieldOption[] => {
  return Array.from({ length: maxOption }, (_, key) => ({
    value: `${key + 1}`,
    label: `${key + 1}`,
  }));
};

const getMaxNumberOfParticipants = ({
  availableSpots,
  maxParticipantsPerBooking,
}: {
  maxParticipantsPerBooking: number;
  availableSpots: number;
}) => {
  return Math.min(maxParticipantsPerBooking, availableSpots);
};

import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import {
  TFunction,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { DurationDataHooks } from './dataHooks';
import { classes, st } from './Duration.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import {
  isServiceTypeIsCourse,
  Service,
} from '../../../../utils/mappers/service.mapper';
import {
  getCourseDuration,
  getFormattedDuration,
} from '../../../../utils/duration/duration';

export interface DurationProps {
  service: Service;
  startDate: string;
  endDate: string;
}
const Duration: React.FC<DurationProps> = ({ service, startDate, endDate }) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const isCourse = isServiceTypeIsCourse(service.type!);
  const duration = getDuration(isCourse, service, startDate, endDate, t);
  return (
    <div className={st(classes.root, { isMobile })}>
      <Text
        data-hook={DurationDataHooks.DURATION}
        className={classes.secondaryText}
        tagName={AccessibilityHtmlTags.Paragraph}
        aria-label={duration.durationAriaText}
      >
        {duration.durationText}
      </Text>
    </div>
  );
};

const getDuration = (
  isCourse: boolean,
  service: Service,
  startDate: string,
  endDate: string,
  t: TFunction,
) => {
  return isCourse
    ? getCourseDuration(service, t)
    : getFormattedDuration({
        startDate,
        endDate,
        t,
      });
};
export default Duration;

import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { ISettingsContextValue, ISettingsValue } from 'yoshi-flow-editor-runtime/tpa-settings';

import { HIGHGLIGHTED_MOCK_PLAN_ID } from '../../fixtures';
import { isTruthy } from '../../utils/isTruthy';
import { PackagePickerSettingsParams } from './settingsParams';

export class DefaultSettingsAdapter {
  constructor(public settings: ISettingsContextValue, public params: PackagePickerSettingsParams) {}

  get<K extends keyof PackagePickerSettingsParams>(key: K) {
    return this.settings.get(this.params[key]);
  }

  set<K extends keyof PackagePickerSettingsParams>(key: K, value: ISettingsValue<PackagePickerSettingsParams[K]>) {
    return this.settings.set(this.params[key], value);
  }

  asArray<K extends keyof PackagePickerSettingsParams>(key: K): string[] {
    return (this.settings.get(this.params[key]) as string).split(',').filter(isTruthy);
  }

  getHighlightedPlanId(plans: PublicPlan[], showDemoHighlight?: boolean) {
    const showHighlightedPlan = this.get('showHighlightedPlan');
    const highlightedPlanId = this.get('highlightedPlan');

    const primaryPlanId = plans.find((plan) => plan.primary)?.id;

    if (showHighlightedPlan && highlightedPlanId) {
      return highlightedPlanId;
    }

    if (showHighlightedPlan && !highlightedPlanId && primaryPlanId) {
      return primaryPlanId;
    }

    return (
      plans.find((p) => p.id === HIGHGLIGHTED_MOCK_PLAN_ID)?.id ||
      (showDemoHighlight && (plans[1]?.id || plans[0]?.id)) ||
      ''
    );
  }

  getHiddenPlanIds(plans: PublicPlan[]): string[] {
    const hiddenPlans = this.asArray('hiddenPlans');
    return plans
      .map((plan) => plan.id)
      .filter(isTruthy)
      .filter((id) => hiddenPlans.includes(id));
  }

  getOrderedPlans(plans: PublicPlan[]): PublicPlan[] {
    const hiddenPlans = this.getHiddenPlanIds(plans);
    const planOrder = this.asArray('planOrder');
    const ordered = planOrder.map((id) => plans.find((p) => p.id === id)).filter(isTruthy);
    const unordered = plans.filter((p) => !planOrder.includes(p.id!));
    return [...ordered, ...unordered].filter((p) => !hiddenPlans.includes(p.id!));
  }

  hidePlan(id: string) {
    const hiddenPlans = this.asArray('hiddenPlans');
    this.set('hiddenPlans', hiddenPlans.concat(id).join(','));
  }

  unhidePlan(id: string) {
    const hiddenPlans = this.asArray('hiddenPlans').filter((hiddenId) => id !== hiddenId);
    this.set('hiddenPlans', hiddenPlans.join(','));
  }

  initializeVisiblePlans(_plans: PublicPlan[]) {}
}

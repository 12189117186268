import {
  FormFieldViewInfoFieldType,
  FormViewField,
  DisplayProperties,
  ValidationProperties,
} from '@wix/forms-ui/types';

import {
  AddressFields,
  FormField,
  ValueType,
} from '@wix/ambassador-services-catalog-server/http';
import { BookingRequestKeyMappings } from './form-submission.mapper';

export type BookingsKeyMapping = {
  key: BookingRequestKeyMappings;
};

export const createFormField = ({
  fieldType,
  field,
  bookingsKeyMapping,
  validations,
  displayProperties,
}: {
  fieldType: FormFieldViewInfoFieldType;
  field?: FormField;
  bookingsKeyMapping?: BookingsKeyMapping;
  validations?: ValidationProperties;
  displayProperties?: DisplayProperties;
}): FormViewField => {
  return {
    externalId: field?.fieldId,
    renderInfo: {
      type: fieldType,
      displayProperties: {
        ...getCheckboxDisplayProps({ field, fieldType }),
        label: field?.label,
        ...displayProperties,
      },
      ...(bookingsKeyMapping
        ? {
            metadata: {
              bookingsKeyMapping,
            },
          }
        : {}),
      validationProperties: {
        required: field?.userConstraints?.required,
        ...validations,
      },
    },
  };
};

export const createAddressField = (addressField: AddressFields) => {
  return createFormField({
    fieldType: FormFieldViewInfoFieldType.MULTI_LINE_ADDRESS,
    field: { fieldId: BookingRequestKeyMappings.FULL_ADDRESS },
    bookingsKeyMapping: {
      key: BookingRequestKeyMappings.FULL_ADDRESS,
    },
    displayProperties: {
      multiLineAddressOptions: {
        state: createFormField({
          fieldType: FormFieldViewInfoFieldType.TEXT,
          field: addressField.state,
        }).renderInfo,
        city: createFormField({
          fieldType: FormFieldViewInfoFieldType.TEXT,
          field: addressField.city,
        }).renderInfo,
        streetLine1: createFormField({
          fieldType: FormFieldViewInfoFieldType.TEXT,
          field: addressField.street,
        }).renderInfo,
        streetLine2: createFormField({
          fieldType: FormFieldViewInfoFieldType.TEXT,
          field: addressField.floorNumber,
        }).renderInfo,
      },
    },
  });
};

export const getFieldType = (valueType: ValueType) => {
  switch (valueType) {
    case ValueType.CHECK_BOX:
      return FormFieldViewInfoFieldType.CHECKBOX;
    case ValueType.LONG_TEXT:
      return FormFieldViewInfoFieldType.PARAGRAPH;
    case ValueType.SHORT_TEXT:
      return FormFieldViewInfoFieldType.TEXT;
    default:
      return FormFieldViewInfoFieldType.TEXT;
  }
};

const getCheckboxDisplayProps = ({
  field,
  fieldType,
}: {
  field?: FormField;
  fieldType: FormFieldViewInfoFieldType;
}) => {
  return fieldType === FormFieldViewInfoFieldType.CHECKBOX
    ? {
        checkboxOptions: {
          link: { url: field?.additionalLabels?.[0]?.linkLabel?.url },
          text: field?.additionalLabels?.[0]?.linkLabel?.label,
        },
      }
    : {};
};

import React from 'react';
import { Money } from '@wix/ambassador-pricing-plans-read-api/http';
import getSymbolFromCurrency from 'currency-symbol-map';

import { st, classes } from './Price.st.css';

interface Props {
  price?: Money;
  highlighted: boolean;
}

export const Price: React.FC<Props> = ({ price, highlighted }) => {
  return (
    <div className={st(classes.wrapper, { highlighted })}>
      <span className={classes.currency}>{getSymbolFromCurrency(price?.currency)}</span>
      <span className={classes.price}>{price?.amount}</span>
    </div>
  );
};

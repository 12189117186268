import { FormState } from '../../../utils/state/initialStateFactory';
import { FormContext } from '../../../utils/context/contextFactory';
import { ViewModelFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import {
  PaymentViewModel,
  createPaymentViewModel,
} from './PaymentViewModel/paymentViewModel';

export type FormViewModel = {
  paymentViewModel: PaymentViewModel;
};

export const createFormViewModel = (
  viewModelFactoryParams: ViewModelFactoryParams<FormState, FormContext>,
): FormViewModel => ({
  paymentViewModel: createPaymentViewModel(viewModelFactoryParams),
});

import React, { useContext } from 'react';
import { DefaultSettingsAdapter } from '../components/PackagePicker/DefaultSettingsAdapter';
import { SettingsParamsProvider } from './settings-params-context';

const SettingsAdapterContext = React.createContext<DefaultSettingsAdapter>({} as DefaultSettingsAdapter);

interface SettingsAdapterProviderProps {
  adapter: DefaultSettingsAdapter;
}

export const SettingsAdapterProvider: React.FC<SettingsAdapterProviderProps> = ({ children, adapter }) => {
  return (
    <SettingsParamsProvider settingsParams={adapter.params}>
      <SettingsAdapterContext.Provider value={adapter}>{children}</SettingsAdapterContext.Provider>
    </SettingsParamsProvider>
  );
};

export const useAdaptedSettings = () => useContext(SettingsAdapterContext);

import {
  BorderStyle,
  AlignmentOptions,
  PaymentMethod,
} from '../../types/types';
import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';

export type ISettingsParams = {
  titlesAlignment: AlignmentOptions;
  fieldsBorderStyle: BorderStyle;
  stretchButtonToFullWidth: SettingsParamType.Boolean;
  formTitle: SettingsParamType.Text;
  formSubtitle: SettingsParamType.Text;
  numberOfParticipantsText: SettingsParamType.Text;
  selectPaymentMethodTitle: SettingsParamType.Text;
  pricingPlanText: SettingsParamType.Text;
  offlinePaymentButtonText: SettingsParamType.Text;
  onlinePaymentButtonText: SettingsParamType.Text;
  requestBookingButtonText: SettingsParamType.Text;
  videoConferenceBadgeVisibility: SettingsParamType.Boolean;
  defaultPaymentMethod: PaymentMethod;
};

export default createSettingsParams<ISettingsParams>({
  titlesAlignment: {
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  fieldsBorderStyle: {
    getDefaultValue: () => BorderStyle.BOX,
  },
  stretchButtonToFullWidth: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  formTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  formSubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  numberOfParticipantsText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  selectPaymentMethodTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  pricingPlanText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  offlinePaymentButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  onlinePaymentButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  requestBookingButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  videoConferenceBadgeVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  defaultPaymentMethod: {
    getDefaultValue: () => PaymentMethod.MEMBERSHIP,
  },
});

import { TFunction } from '../../controller';
import { PaymentDtoMapper } from '@wix/bookings-uou-mappers';
import { ServicePayment, ServicePaymentDto } from '@wix/bookings-uou-types';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { PriceUtils } from '@wix/bookings-uou-domain';
import {
  isCustomPrice,
  isOfferedAsPricingPlanOnly,
  hasMoreParticipantsThenCredit,
  isOfferedAsOneTime,
  hasPurchesedPlans,
  isServiceHavePricingPlans,
  isFreeService,
  isOfferedAsPricingPlan,
  isFixedPrice,
  isOfferedAsOneTimeOnly,
} from '../../../../utils/payment/payment';
import { DateTimeFormatter } from '@wix/bookings-date-time';

export enum PaymentOptionsIds {
  SingleSession = 'SINGLE_SESSION_ID',
  BuyAPricingPlan = 'BUY_A_PRICING_PLAN_ID',
}

export enum PriceSummaryType {
  Total = 'TOTAL',
  Deposit = 'DEPOSIT',
  SingleSession = 'SINGLE_SESSION',
  CustomPrice = 'CUSTOM_PRICE',
  CouponDiscount = 'COUPON_DISCOUNT',
}

export enum PaymentVariationType {
  PP = 'PP',
  BuyPP = 'BUY_PP',
  Custom = 'CUSTOM',
  Fixed = 'FIXED',
}

export type PaymentOption = {
  id?: string;
  checked?: boolean;
  label?: string;
  paymentType?: PaymentVariationType;
  suffix?: string;
  validUntil?: string;
  disabled?: boolean;
};

export type PriceSummary = {
  label?: string;
  type?: PriceSummaryType;
  price?: string;
};

export type PaymentViewModel = {
  paymentOptions?: PaymentOption[];
  priceSummary?: PriceSummary[];
  warning?: string;
  promoCodeText?: string;
};

export function createPaymentViewModel({
  state,
  context,
}: ViewModelFactoryParams<FormState, FormContext>): PaymentViewModel {
  const { t } = context;
  const {
    service,
    isPricingPlanInstalled,
    pricingPlanDetails,
    numberOfParticipants,
    selectedPaymentOptionId,
    couponDiscount,
    businessInfo,
    hasCoupon,
  } = state;
  let priceSummary: PriceSummary[] = [];
  let promoCodeText = '';

  const servicePayment = service.payment;
  const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
  const dateAndTimeFormatter = new DateTimeFormatter(
    dateRegionalSettingsLocale,
  );

  const paymentOptions: PaymentOption[] = getPaymentOptions(
    servicePayment,
    numberOfParticipants,
    pricingPlanDetails!,
    dateRegionalSettingsLocale,
    isPricingPlanInstalled,
    selectedPaymentOptionId!,
    dateAndTimeFormatter,
    t,
  );

  const singleSessionSelected =
    selectedPaymentOptionId === PaymentOptionsIds.SingleSession;

  const isPaymentOptionSingleSession =
    paymentOptions?.[0]?.paymentType ===
    (PaymentVariationType.Fixed || PaymentVariationType.Custom);
  if (
    (paymentOptions.length === 1 && isPaymentOptionSingleSession) ||
    singleSessionSelected
  ) {
    priceSummary = getPriceSummary(
      servicePayment,
      dateRegionalSettingsLocale,
      numberOfParticipants,
      couponDiscount!,
      t,
    );
  }

  const warning = getWarning({
    numberOfParticipants,
    pricingPlanDetails,
    servicePayment,
    paymentOptions,
    selectedPaymentOptionId,
    t,
  });

  if (
    shouldShowPromoCodeText(hasCoupon, servicePayment, singleSessionSelected)
  ) {
    promoCodeText = t('app.payment.coupon.add-a-promo-code.text');
  }

  return {
    warning,
    priceSummary,
    paymentOptions,
    promoCodeText,
  };
}

const shouldShowPromoCodeText = (
  hasCoupon: boolean,
  servicePayment: ServicePayment,
  singleSessionSelected: boolean,
) => {
  return (
    hasCoupon &&
    isFixedPrice(servicePayment) &&
    (singleSessionSelected || isOfferedAsOneTimeOnly(servicePayment))
  );
};
const getWarning = ({
  numberOfParticipants,
  pricingPlanDetails,
  servicePayment,
  paymentOptions,
  selectedPaymentOptionId,
  t,
}: {
  numberOfParticipants: number;
  pricingPlanDetails?: PaidPlans;
  servicePayment?: ServicePayment;
  paymentOptions: PaymentOption[];
  selectedPaymentOptionId?: string;
  t: TFunction;
}) => {
  let warning = '';
  const pricingPlan = pricingPlanDetails?.plans?.find(
    (plan) => plan.paidPlan?.planId === selectedPaymentOptionId,
  );

  if (
    hasMoreParticipantsThenCredit(
      pricingPlan?.creditRemain!,
      numberOfParticipants,
    )
  ) {
    if (paymentOptions.length === 1) {
      warning = t(
        'app.payment.warning.not-enough-sessions-left-reduce-participans-number.text',
      );
    } else if (servicePayment!.offeredAs.length > 1) {
      const hasADisabledPaymentOption = paymentOptions.find(
        (plan) => plan.disabled,
      );

      if (hasADisabledPaymentOption) {
        warning = t(
          'app.payment.warning.not-enough-sessions-left-pay-single-session.text',
        );
      }
    }
  } else if (
    isOfferedAsPricingPlan(servicePayment!) &&
    isFreeService(servicePayment!) &&
    !hasPurchesedPlans(pricingPlanDetails!)
  ) {
    warning = t('app.payment.warning.buy-a-pricing-plan.text');
  }
  return warning;
};

const getPriceText = (
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
): string => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
  return paymentDtoMapper.priceText(paymentDto);
};

const getPriceSummary = (
  servicePayment: ServicePayment,
  dateRegionalSettingsLocale: string,
  numberOfParticipants: number,
  couponDiscount: number,
  t: TFunction,
): PriceSummary[] => {
  const price = servicePayment.paymentDetails.price;
  const currency = servicePayment.paymentDetails.currency;
  const locale = dateRegionalSettingsLocale;
  const priceSummary = [];
  if (couponDiscount > 0) {
    const promoCode = {
      label: t('app.payment.summary.promo-code.text'),
      price: PriceUtils.getFormattedCurrency({
        price: -couponDiscount,
        currency,
        locale,
      }),
      type: PriceSummaryType.CouponDiscount,
    };
    priceSummary.push(promoCode);
  }
  if (numberOfParticipants > 1) {
    const singleSessionPayment = {
      label: t('app.payment.options.single-session.text'),
      price:
        numberOfParticipants +
        ' X ' +
        PriceUtils.getFormattedCurrency({
          price,
          currency,
          locale,
        }),
      type: PriceSummaryType.SingleSession,
    };
    priceSummary.push(singleSessionPayment);
  }
  if (isCustomPrice(servicePayment)) {
    const customPricePayment = {
      label: servicePayment.paymentDetails.priceText,
      type: PriceSummaryType.CustomPrice,
    };
    priceSummary.push(customPricePayment);
  } else {
    const totalPayment = {
      label: t('app.payment.summary.total.text'),
      price: PriceUtils.getFormattedCurrency({
        price: numberOfParticipants * price - couponDiscount,
        currency,
        locale,
      }),
      type: PriceSummaryType.Total,
    };
    priceSummary.push(totalPayment);
  }
  if (servicePayment.paymentDetails.minCharge) {
    const depositPayment = {
      label: t('app.payment.summary.deposit.text'),
      price: PriceUtils.getFormattedCurrency({
        price: servicePayment.paymentDetails.minCharge,
        currency: servicePayment.paymentDetails.currency,
        locale: dateRegionalSettingsLocale,
      }),
      type: PriceSummaryType.Deposit,
    };
    priceSummary.push(depositPayment);
  }
  return priceSummary;
};

const getPaymentOptions = (
  servicePayment: ServicePayment,
  numberOfParticipants: number,
  pricingPlanDetails: PaidPlans,
  dateRegionalSettingsLocale: string,
  isPricingPlanInstalled: boolean,
  selectedPaymentOptionId: string,
  dateAndTimeFormatter: DateTimeFormatter,
  t: TFunction,
): PaymentOption[] => {
  const pricingPlans =
    pricingPlanDetails?.plans?.map((plan) => {
      const disabled = hasMoreParticipantsThenCredit(
        plan.creditRemain!,
        numberOfParticipants,
      );
      return {
        id: plan.paidPlan?.planId,
        checked: plan.paidPlan?.planId === selectedPaymentOptionId,
        label: plan.planName,
        paymentType: PaymentVariationType.PP,
        ...(plan.creditRemain && plan.creditOriginal
          ? { suffix: plan.creditRemain + '/' + plan.creditOriginal }
          : {}),
        ...(plan.validUntil
          ? {
              validUntil: t('app.payment.valid-until.text', {
                validUntil: dateAndTimeFormatter.formatDateAndTime(
                  plan.validUntil,
                ),
              }),
            }
          : {}),
        disabled,
      };
    }) || [];

  const paymentOptions = [...pricingPlans];
  if (
    isPricingPlanInstalled &&
    !isOfferedAsPricingPlanOnly(servicePayment) &&
    !isFreeService(servicePayment) &&
    isServiceHavePricingPlans(servicePayment) &&
    !hasPurchesedPlans(pricingPlanDetails)
  ) {
    const buyAPricingPlanOption = {
      id: PaymentOptionsIds.BuyAPricingPlan,
      checked: PaymentOptionsIds.BuyAPricingPlan === selectedPaymentOptionId,
      label: t('app.payment.options.buy-a-pricing-plan.text'),
      paymentType: PaymentVariationType.BuyPP,
      validUntil: '',
      suffix: '',
      disabled: false,
    };
    paymentOptions.push(buyAPricingPlanOption);
  }
  if (isOfferedAsOneTime(servicePayment) && !isFreeService(servicePayment)) {
    const singleSession = {
      id: PaymentOptionsIds.SingleSession,
      checked: PaymentOptionsIds.SingleSession === selectedPaymentOptionId,
      label: t('app.payment.options.single-session.text'),
      suffix: getPriceText(servicePayment, dateRegionalSettingsLocale),
      paymentType: PaymentVariationType.Fixed,
      disabled: false,
    };
    paymentOptions.push(singleSession);
  }

  return paymentOptions;
};

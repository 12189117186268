import { CreateControllerFn } from 'yoshi-flow-editor-runtime';
import { createMembershipApi, createPricingPlansReadApi, PricingPlansApi } from '../../services';
import { createEventHandler } from 'yoshi-flow-editor-runtime/tpa-settings';
import { IEvents, TabState } from '../../types/common';
import { PlanListController } from './PlanListController';

const createController: CreateControllerFn = async (params) => {
  const {
    flowAPI,
    controllerConfig: { setProps, wixCodeApi, appParams, config },
  } = params;

  // const baseUrl = wixCodeApi.location.baseUrl.split('/').slice(0, 3).join('/');
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const baseUrl = isSSR ? 'https://www.wix.com' : '';
  const api = new PricingPlansApi(
    { Authorization: appParams.instance },
    createMembershipApi(baseUrl),
    createPricingPlansReadApi(baseUrl),
  );

  const componentEventHandler = createEventHandler<IEvents>(config.publicData.COMPONENT || {});
  const pp = new PlanListController(setProps, api, flowAPI, wixCodeApi, appParams);

  return {
    async pageReady() {
      componentEventHandler.on('tabState', (tabState: TabState) => pp.setProps({ tabState }));
      componentEventHandler.onReset(() => pp.setProps({ tabState: TabState.REGULAR }));

      wixCodeApi.site.onInstanceChanged(({ instance }) => api.updateInstance(instance), appParams.appDefinitionId);

      await pp.initializeProps();
    },
    updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
    },
  };
};

export default createController;

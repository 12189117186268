import React from 'react';
import { classes } from './SlotsNotification.st.css';
import { SlotsNotificationViewModel } from '../../ViewModel/slotsNotificationViewModel/slotsNotificationViewModel';
import {
  SectionNotification,
  TEXT_BUTTON_PRIORITY,
  NOTIFICATION_TYPE,
} from 'wix-ui-tpa/SectionNotification';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { useCalendarActions } from '../../Hooks/useCalendarActions';

export type SlotsNotificationProps = {
  viewModel?: SlotsNotificationViewModel;
};

const SlotsNotification: React.FC<SlotsNotificationProps> = ({ viewModel }) => {
  const { onSlotsNotificationCtaClick } = useCalendarActions();

  return viewModel ? (
    <SectionNotification
      type={NOTIFICATION_TYPE.wired}
      className={classes.notification}
      data-hook="time-picker-notification"
    >
      <SectionNotification.Text className={classes.notificationText}>
        {viewModel.messageText}
        {viewModel.ctaText ? (
          <div>
            <TextButton
              className={classes.notificationCTA}
              priority={TEXT_BUTTON_PRIORITY.link}
              data-hook="time-picker-notification-cta"
              onClick={() =>
                onSlotsNotificationCtaClick(viewModel.notificationType!)
              }
            >
              {viewModel.ctaText}
            </TextButton>
          </div>
        ) : null}
      </SectionNotification.Text>
    </SectionNotification>
  ) : null;
};

export default SlotsNotification;

import React from 'react';
import { classes, st } from './Location.st.css';
import { Location as ServiceLocation } from '../../../../utils/mappers/service.mapper';
import { Text } from 'wix-ui-tpa/Text';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { LocationDataHooks } from './dataHooks';
import { TFunction } from '../../controller';
import { LocationType } from '@wix/ambassador-availability-calendar/types';

export interface LocationProps {
  location: ServiceLocation;
}
const Location: React.FC<LocationProps> = ({ location }) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const locationText = getLocationText(location, t);

  return (
    <div className={st(classes.root, { isMobile })}>
      <Text
        data-hook={LocationDataHooks.LOCATION}
        className={classes.secondaryText}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {locationText}
      </Text>
    </div>
  );
};

const getLocationText = (location: ServiceLocation, t: TFunction): string => {
  switch (location?.locationType) {
    case LocationType.OWNER_BUSINESS:
      return location?.name!;
    case LocationType.OWNER_CUSTOM:
      return location?.address!;
    case LocationType.CUSTOM:
      return t('app.booking-details.locations.client-place.text');
    default:
      return '';
  }
};

export default Location;

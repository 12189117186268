import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { Divider } from 'wix-ui-tpa/Divider';
import { st, classes } from './Header.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Header as FormHeader } from '@wix/ambassador-services-catalog-server/http';
import { HeaderDataHooks } from './dataHooks';

export const Header: React.FC<FormHeader> = ({
  title,
  description,
  isDescriptionHidden,
}) => {
  const { isMobile } = useEnvironment();
  const showDescription = !isDescriptionHidden;

  return (
    <div className={st(classes.root, { isMobile })}>
      <Text
        className={classes.title}
        data-hook={HeaderDataHooks.TITLE}
        tagName={AccessibilityHtmlTags.MainHeading}
      >
        {title}
      </Text>
      <Divider
        className={classes.divider}
        data-hook={HeaderDataHooks.DIVIDER}
      />
      {showDescription ? (
        <Text
          className={classes.description}
          data-hook={HeaderDataHooks.DESCRIPTION}
          tagName={AccessibilityHtmlTags.SecondaryHeading}
        >
          {description}
        </Text>
      ) : null}
    </div>
  );
};

import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';

export enum CouponsErrorType {
  ERROR_COUPON_HAS_EXPIRED = 'ERROR_COUPON_HAS_EXPIRED',
  ERROR_COUPON_USAGE_EXCEEDED = 'ERROR_COUPON_USAGE_EXCEEDED',
  ERROR_COUPON_SERVICE_UNAVAILABLE = 'ERROR_COUPON_SERVICE_UNAVAILABLE',
  ERROR_COUPON_DOES_NOT_EXIST = 'ERROR_COUPON_DOES_NOT_EXIST',
}

export type SetCoupon = (couponCode: string) => void;

export function createSetCouponAction(
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): SetCoupon {
  return async (couponCode: string) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi } = actionFactoryParams.context;
    const { numberOfParticipants, slotAvailability, service } = state;
    const slot = slotAvailability?.slot!;
    const rate = service.rate;
    let couponDiscount;
    try {
      const calculateCoupon = await formApi.calculateCoupon(
        couponCode,
        slot,
        numberOfParticipants,
        rate,
      );
      couponDiscount = +calculateCoupon?.couponDetails?.couponDiscount! || 0;
      setState({
        couponDiscount,
      });
    } catch (error) {
      console.log(error);
      // TODO: set state of errors
    }
  };
}

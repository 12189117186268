import * as membersLogic from './membersLogic';
import * as pages from './pages';
import * as componentsWrapper from './wrappers/components';
import * as controllersWrapper from './wrappers/controllers';
import * as pagesService from './services/pages';
import * as pagesGroupWrapper from './wrappers/pagesGroup';
import * as routersWrapper from './wrappers/routers';
import * as menusWrapper from './wrappers/menus';
import * as constants from './constants';
import * as applicationState from './applicationState';
import enforceSequentiality from './enforceSequentiality.ts';
import { BADGES_BM_URL, SITE_MEMBERS_BM_URL } from './constants/routes.ts';
import {
  initializeMonitoring,
  interactionEnded,
  interactionFailed,
  interactionStarted,
  log,
  toMonitored,
} from '../utils/monitoring';
import { createAppManifest } from './manifest';
import * as menusService from './services/menus';
import { parseStaticsUrlFromEditorScriptUrl } from './services/urls';
import * as appState from './services/applicationState.ts';
import { createBIService } from '../utils/bi.ts';
import * as membersIntegrationApi from './services/integration.ts';
import { registerAlwaysAvailableApps } from './services/integration.ts';
import { publishSettingsForMembersAreaApps } from './services/members-area.ts';
import { maybeConductExperiments, shouldDisableBrokenMADeletion, shouldUseNewStyleModals } from '../utils/experiments';
import { runAndWaitForApproval } from './wrappers/transactions.ts';
import { openRemovePagePanel, openUninstallPanel } from './wrappers/platformPanels.ts';
import { getTranslationFunction } from '../i18n';
import { maybeSetCustomProfileParams } from './services/custom-profile';
import { createPublicApi } from './public-api';
import { createPrivateApi } from './private-api';
import { hasSocialPages } from './services/pages';

const { APP_TOKEN } = constants;
let resolveEditorReady;

enforceSequentiality(
  'initial promise',
  new Promise((resolve) => {
    resolveEditorReady = resolve;
  }),
);

// TO DO: Login button element removal is missing
async function removeBrokenInstallation(editorSDK, shouldLog = true, forceDelete = false) {
  if (!forceDelete && (await shouldDisableBrokenMADeletion())) {
    return;
  }

  if (shouldLog) {
    const state = await applicationState.getApplicationComponents(editorSDK);
    const isEmpty = await applicationState.isEmpty(editorSDK);
    const extra = { state: JSON.stringify(state) };

    if (isEmpty) {
      log('Removing components for empty installation', { extra });
    } else {
      log('Removing components for broken installation', { extra });
    }
  }

  try {
    await pages.navigateToFirstPrivatePage(editorSDK);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  await componentsWrapper.removeSospContainer(editorSDK);
  await pagesGroupWrapper.remove(editorSDK);

  try {
    if (await pages.isInMembersAreaSubPage(editorSDK)) {
      await pages.navigateToHomePage(editorSDK);
    }
  } catch (e) {
    log('Failed to check if MA is in the home page, continuing broken MA uninstallation', { extra: e });
  }

  await routersWrapper.removeConnectedPages(editorSDK);
  await routersWrapper.removeAllRouters(editorSDK);
  await controllersWrapper.wipeOut(editorSDK);
  await menusWrapper.removeMenus(editorSDK);
}

async function removeComponentsForBrokenInstallation(editorSDK) {
  const successfullyInstalled = await applicationState.isApplicationReady(editorSDK, { shouldLog: true });
  if (!successfullyInstalled) {
    await toMonitored('editorReady.removeComponentsForBrokenInstallation', () => removeBrokenInstallation(editorSDK));
  }
}

async function maybeInstallMissingRouters(editorSDK) {
  // Just logging for now
  const routers = await routersWrapper.getAll(editorSDK);

  if (routers.length !== 2) {
    const state = await applicationState.getApplicationComponents(editorSDK);
    const extra = {
      routers: JSON.stringify(routers),
      applicationState: JSON.stringify(state),
    };
    log('Missing routers when they should be apparent ', { extra });
  }
}

async function maybeFixBrokenMenuItems(editorSDK) {
  const menuIds = menusWrapper.getMenuIds();
  const menus = await Promise.all([
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.members }),
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.login }),
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.icons }),
  ]);

  // If all menus aren't apparent, don't fix them - MA state is too broken already
  if (menus.filter((m) => !!m).length !== 3) {
    return;
  }

  await menusService.maybeCleanUpMenus(editorSDK);
}

async function maybeFixBrokenInstallation(editorSDK) {
  try {
    await maybeInstallMissingRouters(editorSDK);
    await maybeFixBrokenMenuItems(editorSDK);
  } catch (e) {
    log('An error was thrown while fixing a broken installation, reason: ' + e);
  }
}

async function onManagePages({ eventPayload, editorSDK }) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({ origin: 'editor' });
  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: './assets/managePages.html',
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

async function onEvent({ eventType, eventPayload }, editorSDK) {
  enforceSequentiality('onEventHandler', async () => {
    const isReady = await applicationState.isApplicationReady(editorSDK);
    if (!isReady) {
      return;
    }
    try {
      const _routers = await editorSDK.routers.getAll();
      switch (eventType) {
        case 'createBlankPage': {
          onManagePages({ eventPayload, editorSDK });
          break;
        }
        case 'managePages': {
          onManagePages({ editorSDK });
          break;
        }
        case 'pageDeleted': {
          // Handling when a verticals TPA section is being deleted - removes menu items and router patterns, the page is removed by platform
          // Separate applications deletion (added with "addApplication") is handled by handleVerticalDeletion
          // To do: ask Editor Platform to do this on their side when deleting
          // Seems like we can't return the promise because it then does not execute on e.g. Bookings deletion..
          enforceSequentiality('handleVerticalSectionDeletion', () =>
            toMonitored('handleVerticalSectionDeletion', () =>
              membersIntegrationApi.handleVerticalSectionDeletion(editorSDK, eventPayload.pageRole),
            ),
          );
          break;
        }
        case 'uninstall': {
          const useNewModals = await shouldUseNewStyleModals();
          if (useNewModals) {
            openUninstallPanel(editorSDK);
          } else {
            editorSDK.editor.openModalPanel(APP_TOKEN, {
              url: './assets/uninstall.html',
              width: 564,
              height: 309,
              shouldHideHeader: true,
              initialData: eventPayload,
            });
          }
          break;
        }
        case 'removePage': {
          const useNewModals = await shouldUseNewStyleModals();
          if (useNewModals) {
            openRemovePagePanel(editorSDK, eventPayload.pageRef);
          } else {
            editorSDK.editor.openModalPanel(APP_TOKEN, {
              url: './assets/removePage.html',
              width: 564,
              height: 258,
              shouldHideHeader: true,
              initialData: eventPayload,
            });
          }
          break;
        }
        case 'renameRouter':
          hasSocialPages(editorSDK).then((containsSocialPages) => {
            const height = containsSocialPages
              ? constants.RENAME_ROUTER_PANEL_HEIGHT + 150
              : constants.RENAME_ROUTER_PANEL_HEIGHT;
            editorSDK.editor.openModalPanel(APP_TOKEN, {
              url: './assets/renameRouter.html',
              width: 744,
              height,
              shouldHideHeader: true,
              initialData: Object.assign({ routers: _routers }, eventPayload),
            });
          });
          break;
        case 'componentAddedToStage':
          await componentsWrapper.handleCompAddedToStage(editorSDK, eventPayload.compRef);
          break;
        case 'siteWasPublished':
          publishSettingsForMembersAreaApps(editorSDK);
          break;

        // Apps manager
        case 'appActionClicked':
          switch (eventPayload && eventPayload.actionId) {
            case 'openMembersAreaPagesPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'pagesPanel',
                params: [constants.SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'addMemberPage': {
              onManagePages({ eventPayload, editorSDK });
              break;
            }
            case 'openMembersAddPanel':
              editorSDK.editor.deeplink.show(APP_TOKEN, {
                type: 'addPanel',
                params: [constants.SANTA_MEMBERS_APP_ID],
              });
              break;
            case 'openBadgesPage':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: BADGES_BM_URL, closeOtherPanels: true });
              break;
            case 'openSiteMembersDashboard':
              editorSDK.editor.openDashboardPanel(APP_TOKEN, { url: SITE_MEMBERS_BM_URL, closeOtherPanels: true });
              break;
            default:
              break;
          }
          break;

        /* end of possibly unused events */
        default:
          console.log(eventType, eventPayload);
      }
    } catch (e) {
      throw e;
    }
  });
}

// For investigation purposes of MA-84
async function verifyMyAccountPage(options, editorSDK) {
  if (options.firstInstall) {
    return;
  }

  try {
    const menuItems = await menusWrapper.getMenuItems({ editorSDK, menuId: constants.MENU_IDS.SUB_MENU_ID });
    const myAccountMenuItem = menuItems.find((i) => i.link.innerRoute === 'my-account');
    const allPages = await editorSDK.pages.data.getAll();
    const myAccountPage = allPages.find((p) => p.tpaPageId === 'member_info');

    if (!myAccountPage && !!myAccountMenuItem) {
      log('editorLoad: MA-84 My account menu item is there, but the page is missing');
    }
  } catch (e) {
    log('Verifying My Account page failed', { tags: { reason: e.toString() } });
  }
}

async function verifyNoMissingLoginInADI(firstInstall, editorSDK) {
  try {
    if (!appState.getIsADI()) {
      return;
    }

    const hasLoginComponent = await componentsWrapper.isLoginBarComponentAdded({ editorSDK });

    if (!hasLoginComponent) {
      log('OB-19052: Login component missing in ADI', { tags: { firstInstall } });
      try {
        const controllerRef = await controllersWrapper.getController(editorSDK);
        const header = await editorSDK.siteSegments.getHeader();
        await componentsWrapper.addLoginButton(editorSDK, controllerRef, header);
      } catch (e) {
        log('OB-19052: Re-adding login component failed', { tags: { reason: e.toString() } });
      }
    }
  } catch (e) {
    log('OB-19052: Verification of login component failed', { tags: { reason: e.toString() } });
  }
}

async function maybeRemoveLeftoversFromUnsuccessfulInstallation(_editorSDK, options) {
  const isEmpty = await applicationState.isEmpty(_editorSDK);
  const isReady = await applicationState.isApplicationReady(_editorSDK, { shouldLog: false });
  if (options.firstInstall && !isEmpty && !isReady) {
    const state = await applicationState.getApplicationComponents(_editorSDK);
    await removeBrokenInstallation(_editorSDK, false);
    log('Removing leftover components from previous installations', { extra: { state: JSON.stringify(state) } });
  }
}

async function maybeRemoveEmptyInstallation(_editorSDK, options) {
  const isEmpty = await applicationState.isEmpty(_editorSDK);
  if (!options.firstInstall && isEmpty) {
    log('Removing components for empty installation as it will not install anyway and will surely fail');
    await removeBrokenInstallation(_editorSDK, false);
    return { shouldContinueInitialization: false };
  }
  return { shouldContinueInitialization: true };
}

async function maybeInstallMembersArea(_editorSDK, options) {
  if (await membersLogic.shouldInstall(_editorSDK, options.firstInstall)) {
    try {
      await toMonitored('install', () => membersLogic.install(_editorSDK, options));
    } catch (e) {
      log('Removing initial installation as it failed', { tags: { reason: e.toString() } });
      await removeBrokenInstallation(_editorSDK, true, true);
      throw e;
    }
  }
}

// Old sites pages does not have managingAppDefId, which is needed for platformised pages panel
async function maybeSetManagingAppDefIdForMAPages({ editorSDK: editorSDK_, options }) {
  if (options.firstInstall) {
    return;
  }

  try {
    const routers = await routersWrapper.getAll(editorSDK_);
    const pagesRefs = routers.reduce((acc, router) => [...acc, ...router.pages.map((p) => p.pageRef)], []);
    await Promise.all(
      pagesRefs.map((pageRef) => pagesService.updatePageWithManagingAppDefId({ editorSDK: editorSDK_, pageRef })),
    );
  } catch (e) {
    log('Failed to set managingAppDefId for MA pages, reason:', e.toString());
  }
}

async function editorReady(editorSDK, _appToken, options = {}) {
  maybeConductExperiments();

  appState.setEditorSDK(editorSDK);
  appState.setStaticsBaseUrl(parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl));
  appState.setIsBlogWriterProfilesOnly(options.origin.info?.blogWriterProfilesOnly);
  appState.setIsResponsiveEditor(options.origin.type === 'RESPONSIVE');
  appState.setIsADI(options.origin.type.indexOf('ADI') === 0);

  const biService = await createBIService({ editorSDK });

  try {
    await initializeMonitoring(editorSDK, options);
    interactionStarted('editorReady');

    await runAndWaitForApproval(editorSDK, async () => {
      await maybeSetManagingAppDefIdForMAPages({ editorSDK, options });

      await pagesService.setStateForPages(editorSDK);

      // We see occasions of not entirely deleted MAs without the app actually being installed.
      // This should clean it up and allow a proper installation
      // This shouldn't be happening so need to investigate why this is happening
      await maybeRemoveLeftoversFromUnsuccessfulInstallation(editorSDK, options);

      // Delete empty MAs which won't install anyway to not cause further errors
      // Also don't expose public API in such case as it will not perform properly without MA components
      const { shouldContinueInitialization } = await maybeRemoveEmptyInstallation(editorSDK, options);
      if (!shouldContinueInitialization) {
        return resolveEditorReady();
      }

      // MA-84 investigation, making sure My Account page is always there as it has to be
      await verifyMyAccountPage(options, editorSDK);

      // Install MA and delete it if anything goes wrong
      await maybeInstallMembersArea(editorSDK, options);

      // Try to solve some issues like duplicated menu items and etc, where MA is corrupted but doesn't have to be deleted
      await maybeFixBrokenInstallation(editorSDK);

      // Remove MA if it is still unsuccessfully installed
      await removeComponentsForBrokenInstallation(editorSDK);

      // OB-19052 investigation, check if login component is missing in ADI
      await verifyNoMissingLoginInADI(options.firstInstall, editorSDK);

      await registerAlwaysAvailableApps(editorSDK);

      // Remove this after custom profile is fully opened
      try {
        await toMonitored('maybeSetCustomProfileParams', () => maybeSetCustomProfileParams(editorSDK));
      } catch (e) {
        log('Custom Profile param was not added for the widgets during editor ready', { extra: { e } });
      }

      // Everything that is wrapped with enforceSequentiality runs only after resolveEditorReady is called
      resolveEditorReady();
    });
    interactionEnded('editorReady');
  } catch (e) {
    biService.logInstallationFailure(e.toString());
    interactionFailed('editorReady');
    console.error('membersApplication installation failed.', e);
    console.error('things will certainly not work properly from here on');
    throw new Error('Members Area installation failed: ' + e);
  }
}

async function getAppManifest(options, editorSDK) {
  const t = await getTranslationFunction(editorSDK, true);
  return createAppManifest(editorSDK, t);
}

async function getControllerPresets() {
  return Promise.resolve([]);
}
export const exports_ = (editorSDK) => {
  appState.setEditorSDK(editorSDK);

  return {
    public: createPublicApi(editorSDK),
    private: createPrivateApi(editorSDK),
    editor: {},
  };
};

export { editorReady, onEvent, getAppManifest, getControllerPresets, exports_ as exports };

import React, { useEffect } from 'react';
import Header from './Header/Header';
import { CalendarViewModel } from '../ViewModel/viewModel';
import { CalendarActions } from '../Actions/actions';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { classes, st } from './Widget.st.css';
import EmptyState from './EmptyState/EmptyState';
import { BottomSectionStatus } from '../ViewModel/widgetViewModel/widgetViewModel';
import settingsParams from '../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { CalendarActionsProvider } from '../Hooks/useCalendarActions';
import { CalendarFlowProvider, FlowElements, useFlow } from '../Hooks/useFlow';
import RescheduledBookingDetails from './RescheduledBookingDetails/RescheduledBookingDetails';
import Dialog from './Dialog/Dialog';
import Toast from './Toast/Toast';
import Sidebar from './Sidebar/Sidebar';
import DailyLayoutBody from './DailyLayoutBody/DailyLayoutBody';

export interface ControllerProps extends CalendarActions, CalendarViewModel {}

const WidgetWrapper: React.FC<WidgetProps<ControllerProps>> = (props) => {
  return props.widgetViewModel?.emptyStateViewModel ? (
    <EmptyState {...props.widgetViewModel?.emptyStateViewModel} />
  ) : (
    <CalendarFlowProvider value={props.widgetViewModel.focusedElement}>
      <CalendarActionsProvider value={props}>
        <Widget {...props} />
      </CalendarActionsProvider>
    </CalendarFlowProvider>
  );
};

const Widget: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const settings = useSettings();
  const { isMobile, isRTL } = useEnvironment();

  useEffect(() => {
    if (props.widgetViewModel.status === BottomSectionStatus.LOADING) {
      props.initializeWidget();
    }
  }, [props.widgetViewModel.status]);

  const getWidgetLoader = () => (
    <div data-hook="widget-loader" className={classes.loader}>
      <Spinner diameter={50} isCentered={true} />
    </div>
  );

  const getWidgetContentByStatus = () => {
    switch (props.widgetViewModel.status) {
      case BottomSectionStatus.LOADING:
        return getWidgetLoader();
      case BottomSectionStatus.LOADED:
        return <BottomSectionContent {...props} />;
      default:
        return getWidgetLoader();
    }
  };

  return (
    <div
      className={st(classes.root, {
        isRTL,
        isMobile,
        textAlignment: settings.get(settingsParams.textAlignment),
        columnAlignment: settings.get(settingsParams.columnAlignment),
      })}
      dir={isRTL ? 'rtl' : 'ltr'}
      data-hook="BookingCalendar-wrapper"
    >
      <div data-hook="top-section" className={classes.topSection}>
        <div
          data-hook="top-section-content"
          className={classes.topSectionContentContainer}
        >
          {props.rescheduleDetailsViewModel && (
            <RescheduledBookingDetails
              viewModel={props.rescheduleDetailsViewModel}
            />
          )}
          <Header viewModel={props.headerViewModel!} />
        </div>
      </div>
      <div data-hook="bottom-section" className={classes.bottomSection}>
        <div
          data-hook="bottom-section-content"
          className={classes.bottomSectionContentContainer}
        >
          {getWidgetContentByStatus()}
        </div>
      </div>
      <Dialog viewModel={props.dialogViewModel!} />
      <Toast viewModel={props.toastViewModel!} />
    </div>
  );
};

const BottomSectionContent: React.FC<ControllerProps> = ({
  widgetViewModel: { errorNotificationText },
  dailyLayoutBodyViewModel,
  sidebarViewModel,
}) => {
  const bottomSectionRef = useFlow(FlowElements.BOTTOM_SECTION);

  return (
    <div className={classes.bottomSectionContent} ref={bottomSectionRef}>
      <div className={classes.body}>
        {dailyLayoutBodyViewModel ? (
          <DailyLayoutBody
            viewModel={dailyLayoutBodyViewModel}
            errorNotificationText={errorNotificationText}
          />
        ) : null}
      </div>
      <Sidebar viewModel={sidebarViewModel!} />
    </div>
  );
};

export default WidgetWrapper;

import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  IWixStyleFont,
  IStyleParam,
  IStyleParamValuesByType,
  createStylesParam,
} from 'yoshi-flow-editor-runtime/tpa-settings';
import { TextAlignment, ButtonStyle } from './settingsParams';
import { ISettingsParamsDefaults } from './defaultParams';

type StyleParam<T extends StyleParamType> = IStyleParam<T, IStyleParamValuesByType[T]>;
export type PackagePickerStylesParams = ReturnType<typeof createStylesParamsWithDefaults>;

const buttons = {
  buttonEmptyCorneredFontStyle: createStylesParam('empty-cornered-font-style', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  buttonEmptyCorneredFontColor: createStylesParam('empty-cornered-font-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  buttonEmptyCorneredBorderWidth: createStylesParam('empty-cornered-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  buttonEmptyCorneredCornerRadius: createStylesParam('empty-cornered-corner-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  buttonEmptyCorneredBorderOpacityAndColor: createStylesParam('empty-cornered-border-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  buttonEmptyCorneredBackground: createStylesParam('empty-cornered-bg-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),

  buttonEmptyRoundedFontStyle: createStylesParam('empty-rounded-font-style', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  buttonEmptyRoundedFontColor: createStylesParam('empty-rounded-font-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  buttonEmptyRoundedBorderWidth: createStylesParam('empty-rounded-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  buttonEmptyRoundedCornerRadius: createStylesParam('empty-rounded-corner-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  }),
  buttonEmptyRoundedBorderOpacityAndColor: createStylesParam('empty-rounded-border-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  buttonEmptyRoundedBackground: createStylesParam('empty-rounded-bg-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),

  buttonFullCorneredFontStyle: createStylesParam('full-cornered-font-style', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  buttonFullCorneredFontColor: createStylesParam('full-cornered-font-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  buttonFullCorneredBorderOpacityAndColor: createStylesParam('full-cornered-border-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),
  buttonFullCorneredBorderWidth: createStylesParam('full-cornered-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  buttonFullCorneredCornerRadius: createStylesParam('full-cornered-corner-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  buttonFullCorneredBackground: createStylesParam('full-cornered-bg-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),

  buttonFullRoundedFontStyle: createStylesParam('full-rounded-font-style', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  buttonFullRoundedFontColor: createStylesParam('full-rounded-font-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  buttonFullRoundedBorderOpacityAndColor: createStylesParam('full-rounded-border-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),
  buttonFullRoundedBorderWidth: createStylesParam('full-rounded-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  buttonFullRoundedCornerRadius: createStylesParam('full-rounded-corner-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  }),
  buttonFullRoundedBackground: createStylesParam('full-rounded-bg-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
};

export const createStylesParamsWithDefaults = (defaults: ISettingsParamsDefaults = {}) => ({
  textAlignment: createStylesParam('text-alignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => TextAlignment.CENTER,
  }),
  buttonStyle: createStylesParam('button-style', {
    type: StyleParamType.Number,
    getDefaultValue: () => ButtonStyle.FULL_CORNERED,
  }),

  backgroundColor: createStylesParam('app-bg-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  titleBackgroundColor: createStylesParam('plan-top-bg-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),
  titleBackgroundColorHighlighted: createStylesParam('primary-top-base-bg-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.1),
  }),
  planBenefitBackgroundColor: createStylesParam('plan-benefits-bg-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),
  planBenefitBackgroundColorHighlighted: createStylesParam('primary-benefits-base-bg-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  }),
  cardBorderWidth: createStylesParam('card-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  cardBorderColor: createStylesParam('card-border-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  }),
  cardBorderColorHighlighted: createStylesParam('card-border-color-highlighted', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  }),
  dividerWidth: createStylesParam('divider-width', {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) =>
      // @ts-ignore
      getStyleParamValue({ type: StyleParamType.Boolean, key: 'show-benefits-dividers' }) ? 1 : 0,
  }),
  dividerColor: createStylesParam('divider-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  }),
  dividerColorHighlighted: createStylesParam('divider-color-highlighted', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  }),
  pageHeaderFont: createStylesParam('page-header-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title'),
  }),
  pageHeaderColor: createStylesParam('page-header-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  benefitsSpacing: createStylesParam('benefits-spacing', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1.5,
  }),
  horizontalPadding: createStylesParam('horizontal-padding', {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  }),
  verticalPadding: createStylesParam('vertical-padding', {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  }),
  planTitleFont: createStylesParam('plan-title-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  }),
  planTitleColor: createStylesParam('plan-title-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  planPriceFont: createStylesParam('plan-price-font', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 70 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  planPriceColor: createStylesParam('plan-price-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  planTaglineFont: createStylesParam('plan-tagline-font', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  planTaglineColor: createStylesParam('plan-tagline-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  planBenefitsFont: createStylesParam('plan-benefits-font', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  planBenefitsColor: createStylesParam('plan-benefits-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  planRecurringOptionsFont: createStylesParam('plan-recurring-options-font', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 12 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  planRecurringOptionsColor: createStylesParam('plan-recurring-options-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  planDurationFont: createStylesParam('plan-duration-font', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 12 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  planDurationColor: createStylesParam('plan-duration-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  }),
  showPageTitle: createStylesParam('show-page-title', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => (defaults.showPageTitle !== undefined ? defaults.showPageTitle : true),
  }),
  showPlanName: createStylesParam('show-plan-name', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showTagline: createStylesParam('show-plan-tagline', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showBenefits: createStylesParam('show-plan-benefits', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showBenefitsDividers: createStylesParam('show-benefits-dividers', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showDisclaimer: createStylesParam('show-disclaimer', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  showRibbon: createStylesParam('show-ribbon', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  ribbonBackgroundColor: createStylesParam('ribbon-bg-opacity-and-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-18'),
  }),
  ribbonFont: createStylesParam('ribbon-font', {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      const { value: _, ...font } = wixFontParam('Body-M', { size: 14 })({ textPresets });
      return { ...font } as IWixStyleFont;
    },
  }),
  ribbonColor: createStylesParam('ribbon-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  ribbonBorderWidth: createStylesParam('ribbon-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  ribbonBorderColor: createStylesParam('ribbon-border-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0),
  }),
  planTitleColorHighlighted: createStylesParam('plan-title-color-highlighted', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  planPriceColorHighlighted: createStylesParam('plan-price-color-highlighted', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  planRecurringOptionsColorHighlighted: createStylesParam('plan-recurring-options-color-highlighted', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  planTaglineColorHighlighted: createStylesParam('plan-tagline-color-highlighted', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  planDurationColorHighlighted: createStylesParam('plan-duration-color-highlighted', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  }),
  planBenefitsColorHighlighted: createStylesParam('plan-benefits-color-highlighted', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),

  ...buttons,

  buttonEmptyCorneredFontColorHighlighted: deriveHighlighted(buttons.buttonEmptyCorneredFontColor),
  buttonEmptyCorneredCornerRadiusHighlighted: deriveHighlighted(buttons.buttonEmptyCorneredCornerRadius),
  buttonEmptyCorneredBorderOpacityAndColorHighlighted: deriveHighlighted(
    buttons.buttonEmptyCorneredBorderOpacityAndColor,
  ),
  buttonEmptyCorneredBackgroundHighlighted: deriveHighlighted(buttons.buttonEmptyCorneredBackground),

  buttonEmptyRoundedFontColorHighlighted: deriveHighlighted(buttons.buttonEmptyRoundedFontColor),
  buttonEmptyRoundedCornerRadiusHighlighted: deriveHighlighted(buttons.buttonEmptyRoundedCornerRadius),
  buttonEmptyRoundedBorderOpacityAndColorHighlighted: deriveHighlighted(
    buttons.buttonEmptyRoundedBorderOpacityAndColor,
  ),
  buttonEmptyRoundedBackgroundHighlighted: deriveHighlighted(buttons.buttonEmptyRoundedBackground),

  buttonFullCorneredFontColorHighlighted: deriveHighlighted(buttons.buttonFullCorneredFontColor),
  buttonFullCorneredCornerRadiusHighlighted: deriveHighlighted(buttons.buttonFullCorneredCornerRadius),
  buttonFullCorneredBorderOpacityAndColorHighlighted: deriveHighlighted(
    buttons.buttonFullCorneredBorderOpacityAndColor,
  ),
  buttonFullCorneredBackgroundHighlighted: deriveHighlighted(buttons.buttonFullCorneredBackground),

  buttonFullRoundedFontColorHighlighted: deriveHighlighted(buttons.buttonFullRoundedFontColor),
  buttonFullRoundedCornerRadiusHighlighted: deriveHighlighted(buttons.buttonFullRoundedCornerRadius),
  buttonFullRoundedBorderOpacityAndColorHighlighted: deriveHighlighted(buttons.buttonFullRoundedBorderOpacityAndColor),
  buttonFullRoundedBackgroundHighlighted: deriveHighlighted(buttons.buttonFullRoundedBackground),
});

export default createStylesParamsWithDefaults();

function deriveHighlighted<T extends StyleParamType>(regular: StyleParam<T>): StyleParam<T> {
  return {
    ...regular,
    key: regular.key + '-highlighted',
    getDefaultValue: (params) =>
      params.getStyleParamValue(regular) || (regular.getDefaultValue && regular.getDefaultValue(params)),
  };
}

import React from 'react';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useSettings, useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';

import { st, classes } from './Widget.st.css';
import { PlansList } from './PlanList';
import { EmptyState } from './EmptyState';
import { Popup } from './Popup';
import { TabState, PopupEnum, MessageCode } from '../../../types/common';
import { TextAlignment } from '../settingsParams';
import { useSettingsParams } from '../../../services/settings-params-context';
import { useStylesParams } from '../../../services/styles-params-context';

export interface ListProps {
  plans: PublicPlan[];
  tabState: TabState;
  isMobile?: boolean;
  popup: PopupEnum | null;
  message?: MessageCode;
  dataHook?: string;
  selectPlan(plan: PublicPlan): void;
  hidePopup(): void;
}

export const List: React.FC<ListProps> = ({
  plans,
  tabState,
  selectPlan,
  isMobile,
  popup,
  hidePopup,
  message,
  dataHook,
}) => {
  const { t } = useTranslation();
  const settingsParams = useSettingsParams();
  const stylesParams = useStylesParams();
  const settings = useSettings();
  const styles = useStyles();
  const showPageTitle = styles.get(stylesParams.showPageTitle);
  const showDisclaimer = styles.get(stylesParams.showDisclaimer);
  const textAlignment = textAlignmentName(styles.get(stylesParams.textAlignment));

  return (
    <div
      className={st(classes.root, { mobile: !!isMobile, textAlignment })}
      data-hook={dataHook ?? 'PackagePicker-wrapper'}
    >
      {plans.length ? (
        <>
          <div className={classes.header}>
            {showPageTitle && (
              <h1 data-hook="app-title">{settings.get(settingsParams.pageHeaderText) || t('pp.defaults.pageTitle')}</h1>
            )}
          </div>
          {message ? <div data-hook="toast">{t(toErrorMessageKey(message))}</div> : null}
          <PlansList
            isMobile={isMobile}
            selectPlan={selectPlan}
            plans={plans}
            showDemoHighlight={tabState === TabState.HIGHLIGHTED}
          />
          {showDisclaimer && (
            <div className={classes.disclaimer}>
              <p>{settings.get(settingsParams.disclaimerText)}</p>
            </div>
          )}
        </>
      ) : (
        <EmptyState />
      )}
      <Popup popup={popup} hidePopup={hidePopup} />
    </div>
  );
};

function toErrorMessageKey(message: MessageCode): string {
  switch (message) {
    case MessageCode.PURCHASE_LIMIT_ERROR:
      return 'pp.1-purchase-plan-error';
    case MessageCode.PLAN_NOT_FOUND:
      return 'pp.checkout-missing-plan';
    default:
      return 'pp.generic-purchase-error';
  }
}

enum TextAligmentName {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

const textAlignmentMap = {
  [TextAlignment.LEFT]: TextAligmentName.LEFT,
  [TextAlignment.CENTER]: TextAligmentName.CENTER,
  [TextAlignment.RIGHT]: TextAligmentName.RIGHT,
};

function textAlignmentName(value: TextAlignment): TextAligmentName {
  return textAlignmentMap[value];
}

import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookingsForm/settingsParams';
import { PaymentOptionsIds } from '../../components/BookingsForm/ViewModel/PaymentViewModel/paymentViewModel';
import { PaymentMethod } from '../../types/types';
import {
  getActiveSchedule,
  getServiceType,
  isServiceTypeIsCourse,
  mapCatalogServiceToService,
  Service,
} from '../mappers/service.mapper';
import { BusinessInfoBase } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { TFunction } from '../../components/BookingsForm/controller';
import { FormApi } from '../../api/FormApi';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { createDummyState } from '../dummies/dummy-data';

export type FormState = {
  service: Service;
  isPricingPlanInstalled: boolean;
  hasCoupon: boolean;
  businessInfo: BusinessInfoBase;
  activeFeatures: GetActiveFeaturesResponse;
  slotAvailability?: SlotAvailability;
  numberOfParticipants: number;
  couponDiscount?: number;
  pricingPlanDetails?: PaidPlans;
  memberDetails?: Member;
  errors: any[];
  selectedPaymentOptionId?: string;
};

export async function createInitialState({
  t,
  flowApi,
  wixSdkAdapter,
  formApi,
}: {
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
}): Promise<FormState> {
  const { settings, controllerConfig } = flowApi;
  if (wixSdkAdapter.isEditorMode()) {
    const catalogData = await formApi.getCatalogData({});
    return createDummyState(flowApi, catalogData.businessInfo);
  }
  const {
    hasCoupon,
    catalogData,
    listSlots,
    memberDetails,
    slotAvailability,
    pricingPlanDetails,
    isPricingPlanInstalled,
  } = await fetchInitialData({ formApi, controllerConfig, wixSdkAdapter });

  return {
    activeFeatures: catalogData.activeFeatures,
    service: mapCatalogServiceToService({
      catalogData,
      slotAvailability,
      pricingPlanDetails,
      t,
      listSlots,
    }),
    businessInfo: catalogData.businessInfo,
    slotAvailability,
    isPricingPlanInstalled,
    couponDiscount: 0,
    pricingPlanDetails,
    memberDetails,
    numberOfParticipants: 1,
    errors: [],
    selectedPaymentOptionId: getDefaultPaymentOptionId(
      settings,
      pricingPlanDetails,
    ),
    hasCoupon,
  };
}

export const getDefaultPaymentOptionId = (
  settings: ControllerFlowAPI['settings'],
  pricingPlanDetails?: PaidPlans,
) => {
  const planId = pricingPlanDetails?.defaultPlan?.paidPlan?.planId;
  if (planId) {
    return planId;
  } else {
    return settings.get(settingsParams.defaultPaymentMethod) ===
      PaymentMethod.SINGLE
      ? PaymentOptionsIds.SingleSession
      : PaymentOptionsIds.BuyAPricingPlan;
  }
};

const fetchInitialData = async ({
  formApi,
  controllerConfig,
  wixSdkAdapter,
}: {
  formApi: FormApi;
  controllerConfig: IWidgetControllerConfig;
  wixSdkAdapter: WixOOISDKAdapter;
}) => {
  const serviceId = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.SERVICE_ID,
  );
  const slotAvailabilityParam = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.AVAILABILITY_SLOT,
  );
  const slotAvailability: SlotAvailability = slotAvailabilityParam
    ? JSON.parse(atob(slotAvailabilityParam))
    : undefined;
  const resourceId = slotAvailability?.slot!.resource!.id!;
  const startTime = slotAvailability?.slot!.startDate!;
  const user = controllerConfig.wixCodeApi.user.currentUser;
  const isLoggedInUser = user.loggedIn;
  const shouldGetPricingPlanDetails = isLoggedInUser && startTime;

  const [
    hasCoupon,
    catalogData,
    memberDetails,
    pricingPlanDetails,
    isPricingPlanInstalled,
  ] = await Promise.all([
    formApi.isCouponsDefinedOnService(),
    formApi.getCatalogData({ serviceId, resourceId }),
    isLoggedInUser ? formApi.getMemberDetails(user.id) : undefined,
    shouldGetPricingPlanDetails
      ? formApi.getPricingPlanDetails({
          serviceId,
          startTime,
        })
      : undefined,
    wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
  ]);

  const activeSchedule = getActiveSchedule(catalogData?.service!);
  const scheduleId = activeSchedule?.id!;
  const firstSessionStart = activeSchedule?.firstSessionStart;
  const lastSessionEnd = activeSchedule?.lastSessionEnd;

  const type = getServiceType(activeSchedule);
  const isCourse = isServiceTypeIsCourse(type);
  const listSlots =
    isCourse && firstSessionStart && lastSessionEnd
      ? await formApi.getSlots({
          firstSessionStart,
          lastSessionEnd,
          scheduleId,
        })
      : {};

  return {
    catalogData,
    listSlots,
    memberDetails,
    slotAvailability,
    pricingPlanDetails,
    isPricingPlanInstalled,
    hasCoupon,
  };
};

export interface Experiments {
  isShowAllServicesCategoryEnabled?: boolean;
  isRenderImgElement?: boolean;
  isWidgetAdditionalPaddingEnabled?: boolean;
  isWidgetPaddingImprovementEnabled?: boolean;
  isMultiParticipantStepEnabled?: boolean;
  isEnforceIndividualAsPremiumEnabled?: boolean;
  isFullWidthPaddingEnabled?: boolean;
  isCategoryLayoutImprovementDesktopEnabled?: boolean;
  isServiceListPageCategoryUpdateUrlEnabled?: boolean;
  isUseIntlToFormatCourseDurationEnabled?: boolean;
  isAccessibilityImprovementsEnabled?: boolean;
  isImageDimensionsCalcByParent?: boolean;
  isAccessibilityHeadingTagEnabled?: boolean;
  isUseIntlForPriceInBookFlowEnabled?: boolean;
  isUoUMultiLocationV1Enabled?: boolean;
  isServiceFormMultiLocationEnabled?: boolean;
  isWixOSEnableMultiLocationEnabled?: boolean;
  isUouZoomV2Enabled?: boolean;
  isUouOnlineBadgeEnabled?: boolean;
  isServiceListSettingsThemeChange?: boolean;
  isFormatTimezoneDateByBOConfigurationOnClientSide?: boolean;
  isBookingsWidgetUseThumbnailImageEnabled?: boolean;
}

export const experimentsSpecMap: { [key in keyof Experiments]: string } = {
  isShowAllServicesCategoryEnabled:
    'specs.bookings.WidgetShowAllServicesCategoryPresent',
  isImageDimensionsCalcByParent:
    'specs.bookings.WidgetImageDimensionsCalcByList',
  isWidgetAdditionalPaddingEnabled: 'specs.bookings.WidgetAdditionalPadding',
  isWidgetPaddingImprovementEnabled: 'specs.bookings.WidgetPaddingImprovement',
  isMultiParticipantStepEnabled: 'specs.bookings.MultiParticipantStep',
  isEnforceIndividualAsPremiumEnabled:
    'specs.bookings.EnforceIndividualAsPremium',
  isFullWidthPaddingEnabled: 'specs.bookings.WidgetFullWidthPadding',
  isCategoryLayoutImprovementDesktopEnabled:
    'specs.bookings.CategoryLayoutImprovementDesktop',
  isServiceListPageCategoryUpdateUrlEnabled:
    'specs.bookings.ServiceListPageCategoryUpdateUrl',
  isUseIntlToFormatCourseDurationEnabled:
    'specs.bookings.UseIntlToFormatCourseDuration',
  isAccessibilityImprovementsEnabled:
    'specs.bookings.AccessibilityImprovements',
  isAccessibilityHeadingTagEnabled:
    'specs.bookings.headingTagAccessibilitySpec',
  isUseIntlForPriceInBookFlowEnabled:
    'specs.bookings.UseIntlForPriceInBookFlowSpecs',
  isUoUMultiLocationV1Enabled: 'specs.bookings.UoUMultiLocationV1',
  isServiceFormMultiLocationEnabled: 'specs.bookings.ServiceFormMultiLocation',
  isWixOSEnableMultiLocationEnabled: 'specs.wossm.EnableMultiLocation',
  isUouZoomV2Enabled: 'specs.bookings.UouZoomV2',
  isUouOnlineBadgeEnabled: 'specs.bookings.UouListOnlineBadge',
  isServiceListSettingsThemeChange:
    'specs.bookings.ServiceListSettingsThemeChange',
  isFormatTimezoneDateByBOConfigurationOnClientSide:
    'specs.bookings.FormatTimezoneDateByBOConfigurationOnClientSide',
  isRenderImgElement: 'specs.bookings.RenderImgElement',
  isBookingsWidgetUseThumbnailImageEnabled:
    'specs.bookings.bookingsWidgetUseThumbnailImage',
};

function isExperimentsTrue(experimentValue) {
  return experimentValue === 'true' || experimentValue === 'new';
}

export function mapExperiments(
  experiments: any,
  defaultValue = false,
): Experiments {
  const mappedExperiments: Experiments = {};

  Object.entries(experimentsSpecMap).forEach((entry) => {
    const experimentEnablerName = entry[0];
    const experimentSpec = entry[1];
    mappedExperiments[experimentEnablerName] =
      experiments && experiments[experimentSpec]
        ? isExperimentsTrue(experiments[experimentSpec])
        : defaultValue;
  });

  return mappedExperiments;
}

import React, { useEffect } from 'react';
import { classes } from './FormInputs.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  FormFieldViewInfo,
  FormFieldViewInfoFieldType,
  FormRef,
  FormView,
  MessageType,
  SubmissionResponse,
} from '@wix/forms-ui/types';
import { Form } from '@wix/forms-ui/tpa';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { FormInputsDataHooks } from './dataHooks';
import { Member } from '@wix/ambassador-members-ng-api/types';
import {
  BookingRequestKeyMappings,
  mapMemberDetailsToSubmission,
} from '../../../../utils/mappers/form-submission.mapper';

export interface FormInputsProps {
  formSchema: FormView;
  memberDetails?: Member;
  formRef: React.MutableRefObject<FormRef | undefined>;
}

export type SubmitForm = () => SubmissionResponse | undefined;

const FormInputs: React.FC<FormInputsProps> = ({
  formSchema,
  memberDetails,
  formRef,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();

  useEffect(() => {
    if (memberDetails) {
      formRef!.current!.setFieldValue(
        mapMemberDetailsToSubmission(
          [
            BookingRequestKeyMappings.EMAIL,
            BookingRequestKeyMappings.PHONE,
            BookingRequestKeyMappings.FIRST_NAME,
            BookingRequestKeyMappings.LAST_NAME,
          ],
          formSchema,
          memberDetails,
        ),
      );
    }
  }, [memberDetails]);

  const getInvalidPatternByType = (type?: FormFieldViewInfoFieldType) => {
    switch (type) {
      case FormFieldViewInfoFieldType.EMAIL:
        return t('app.form-inputs.validation-errors.invalid-email');
      case FormFieldViewInfoFieldType.PHONE_COUNTRY_CODE:
        return t('app.form-inputs.validation-errors.invalid-phone');
    }
    return '';
  };

  const getValidationErrorMessage = ({
    type,
    context: { renderInfo },
  }: {
    type: MessageType;
    context: { renderInfo?: FormFieldViewInfo };
  }) => {
    switch (type) {
      case 'VALUE_REQUIRED':
        return t('app.form-inputs.validation-errors.required-field');
      case 'INVALID_PATTERN':
        return getInvalidPatternByType(renderInfo?.type);
      default:
        return '';
    }
  };

  return (
    <Form
      ref={formRef}
      className={classes.root}
      data-hook={FormInputsDataHooks.FORM_COMPONENT}
      translateMessage={getValidationErrorMessage}
      theme={settings.get(settingsParams.fieldsBorderStyle)}
      schema={formSchema}
      onSubmit={() => {}}
      isMobile={isMobile}
    />
  );
};
export default FormInputs;

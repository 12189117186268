import React from 'react';
import { useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';

import { useStylesParams } from '../../../../services/styles-params-context';
import { st, classes } from './Tagline.st.css';

interface Props {
  description: string;
  highlighted: boolean;
}

export const Tagline: React.FC<Props> = ({ description, highlighted }) => {
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const showPlanTagline = styles.get(stylesParams.showTagline);

  if (!showPlanTagline) {
    return null;
  }

  return (
    <div className={st(classes.tagline, { highlighted })}>
      <span>{description}</span>
    </div>
  );
};

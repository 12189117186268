import { withDependencies } from '@wix/thunderbolt-ioc'
import { LoggerSymbol, ILinkClickHandler, ILogger } from '@wix/thunderbolt-symbols'
// eslint-disable-next-line no-restricted-syntax
import { isMailtoUrl, isPhoneUrl, isWhatsappLink } from '@wix/thunderbolt-commons/src/platform/linkPatternUtils'

export const ReportBiClickHandlerFactory = (logger: ILogger): ILinkClickHandler => {
	return {
		handleClick: (anchorTarget: HTMLElement) => {
			const href = anchorTarget.getAttribute('href') || ''
			if (isPhoneUrl(href)) {
				logger.meter('phone-clicked')
			}
			if (isMailtoUrl(href)) {
				logger.meter('email-clicked')
			}
			if (isWhatsappLink(href)) {
				logger.meter('whatsapp-clicked')
			}
			return false
		},
	}
}

export const ReportBiClickHandler = withDependencies([LoggerSymbol], ReportBiClickHandlerFactory)

import React from 'react';
import { classes, st } from './StaffMembers.st.css';
import { Text } from 'wix-ui-tpa/Text';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { StaffMembersDataHooks } from './dataHooks';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { StaffMember } from '@wix/bookings-uou-types';

export const STAFF_MEMBER_DELIMITER = ' , ';
export interface StaffMembersProps {
  staffMembers: StaffMember[];
}
const StaffMembers: React.FC<StaffMembersProps> = ({ staffMembers }) => {
  const { isMobile } = useEnvironment();
  const staffMemberNames = getStaffMembers(staffMembers);
  return (
    <div className={st(classes.root, { isMobile })}>
      <Text
        data-hook={StaffMembersDataHooks.STAFF_MEMBER}
        className={classes.secondaryText}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {staffMemberNames}
      </Text>
    </div>
  );
};

const getStaffMembers = (staffMembers: StaffMember[]) => {
  return staffMembers
    .map((staffMember) => staffMember.name)
    .join(STAFF_MEMBER_DELIMITER);
};

export default StaffMembers;

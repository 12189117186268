import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useTranslation } from 'yoshi-flow-editor-runtime';

import { st, classes } from './FreeTrial.st.css';
import { getFreeTrialDaysLabel } from '../../../../utils';

interface Props {
  plan: PublicPlan;
  freeTrialDaysExist: boolean;
  highlighted: boolean;
}

export const FreeTrial: React.FC<Props> = ({ plan, freeTrialDaysExist, highlighted }) => {
  const { t } = useTranslation();

  if (!freeTrialDaysExist) {
    return null;
  }

  return <span className={st(classes.duration, { highlighted })}>{getFreeTrialDaysLabel(plan, t)}</span>;
};
